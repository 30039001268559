@import '../../styles/variables.scss';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: left;
  vertical-align: middle;
  margin: 10px 10px 0;

  
  &-help_text {
    color: var(--gx-color-primary);
    font-size: 12px;
    letter-spacing: 0.04em;
  }
  &-link {
    color: $a2;
    font-size: 12px;
    letter-spacing: 0.04em;
  }
}
