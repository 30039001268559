@import '../../../styles/variables.scss';
@import '../../../styles/fonts/index.scss';

//.preview-image{}
.preview-image{
    padding: 0;    
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    overflow: auto;

    @include scrollbar;
    //.preview-image__container-preview{}
    &__container-preview{
        overflow: hidden;
        position: relative;

    }
    //.preview-image__container-control-button{}
    &__container-control-button{
        position: relative;
        display: block;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
}