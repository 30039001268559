@import '../../styles/variables.scss';

//social-links{}
.social-links {
  //.social-links__list{}
  &__list {
    @include ul;
    @include flex(lc);
    //.social-links__list-item{}
    &-item {
      position: relative;
      margin-right: 30px;
      transition: 0.3s;
      filter: brightness(0);
      cursor: pointer;
      // &::after {
      //     position: absolute;
      //     content: '';
      //     top: 0;
      //     left: 0;
      //     width: 100%;
      //     height: 100%;
      //     background-color: var(--gx-color-primary2);
      //     opacity: 1;
      //     mix-blend-mode: color;
      // }
      @include mobile {
        margin: 0 2px 0 0;
      }
      &:last-child {
        margin: 0;
      }
      &:hover {
        cursor: pointer;
        filter: none;
        // &::after {
        //     opacity: 0;
        // }
      }
      //.social-links__list-item-icon{}
      &-icon {
        width: 40px;
        height: 40px;
      }
    }
    @include tablet-lg {
      justify-content: center;
    }
    @include mobile {
      justify-content: space-between;
    }
  }
}
