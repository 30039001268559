@import '../../../styles/variables.scss';

//.bottom-footer{}
.bottom-footer {
  background-color: $w;
  padding: 20px 0;
  @include padding-for-mobile;

  @include mobile {
    // padding-bottom: 80px;

    padding-bottom: 70px;
  }
//.bottom-footer__container{}
&__container{
  width: 100%;
}
//.bottom-footer__wrap{}
  &__wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px;
    align-items: center;

    @include tablet-lg {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 20px;

      div {
        text-align: center;
      }
    }
  }
//.bottom-footer__info{}
//.bottom-footer__copyright{}
  &__copyright,
  &__info {
    display: flex;
    position: relative;
  }

  &__copyright {
    position: relative;
    padding-right: 30px;
    text-transform: uppercase;

    &::after {
      position: absolute;
      content: '';
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 20px;
      width: 1px;
      background-color: $p;
    }
  }
//.bottom-footer__offer{}
  &__offer {
    // font-family: 'Nato Sans', sans-serif;
    padding-left: 15px;
    color: var(--gx-color-primary);
    font-size: 14px;
    cursor: pointer;

    &:hover {
      color: $a2;
    }
  }
}