@import '../../../styles/variables.scss';

.button_registration{
    margin-bottom: 30px;
}

.auth-email{
    border: 2px outset;
}

.prodpage-sizes__btn{
    padding: 0;
    margin-top: 20px;
    margin-bottom: 35px;
}