@import '../../../../styles/variables.scss';
@import '../../../../styles/fonts/index.scss';
.widget {
  //.widget__wrap{}
  &__wrap {
    //   УБРАТЬ display: none;
    //   display: none;
    //   УБРАТЬ display: none;
    position: fixed;
    bottom: auto;
    right: 0;
    max-width: 500px;
    // height: 100%;
    z-index: 10000000;
    width: 100%;
    @include mobile {
      max-width: 100%;
    }
  }
  &__chat {
    box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.15);
    height: 100%;
    display: flex;
    flex-direction: column;
    //.widget__chat-head{}
    &-head {
      display: flex;
      // @include flex(sc);
      padding: 20px;
      background-color: $a2;
      //.widget__chat-head-text{}
      &-text {
        @extend %font-head;
        color: $w;
        font-weight: bold;
        font-size: 28px;
        line-height: 120%;
        letter-spacing: 0.08em;
        min-width: max-content;
      }
      //.widget__chat-head-btn{}
      &-btn {
        margin-left: 20px;
      }
      &-icon {
        color: $w;
        opacity: 0.8;
      }
      //.widget__chat-head-buttons
      &-buttons{
        display: flex;
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 5px;
        width: 100%;
      }
    }
    //.widget__chat-field{}
    &-field {
      min-height: 450px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 15px 10px;
      background-color: $w;
      @include scrollbar;
      height: calc(100vh - 194px);

      // webkit
      &::-webkit-scrollbar {
        width: 4px;
        background-color: rgba(40, 37, 52, 0.1); /* «цвет полосы скроллбара» */
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(147, 52, 104, 0.3); /* «цвет ползунка» */
      }
    }
    //.
    &-send {
      background-color: $w;
    }

    &-message {
      border-radius: 10px;
      padding: 10px;
      width: 95%;
      margin-bottom: 10px;
      //.widget__chat-message-admin{}
      &-admin {
        align-self: flex-start;
        background-color: rgba(var(--gx-color-accent2-rgb), 0.3);
      }
      //.widget__chatmessage-full{}
      &-full {
        border-radius: 10px;
        padding: 10px;
        width: 100%;
        margin-bottom: 10px;
      }
      //.widget__chat-message-user{}
      &-user {
        align-self: flex-end;
        background-color: $g2;
      }
      //.widget__chat-message-notify{}
      &-notify {
        margin: 0;
        font-weight: bold;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: $a2;
      }
      //.widget__chat-message-wrapper{}
      &-wrapper {
        @include flex(sc);
        margin-bottom: 5px;
      }
      //.widget__chat-message-name{}
      &-name {
        color: $a2;
        font-weight: bold;
        line-height: 140%;
      }

      &-date {
        // color: $p;
        opacity: 0.5;
      }
      //.widget__chat-message-text{}
      &-text {
        margin: 0;
      }
    }
    //.
    &-button {

      align-self: flex-end;
      margin-bottom: 10px;
      background-color: $g2;
      border: 1px solid $a2;
      padding: 13px;
      min-height: 47px;
      border-radius: 10px;
      min-width: 190px;
      font-size: 14px;
      line-height: 140%;
      color: $a2;
      &:hover {
          background-color: $a2;
          color: $w;
      }
      //.widget__chat-button-text{}
      &-text {
        margin-bottom: 10px;
        background-color: $g2;
        border: 1px solid $a2;
        padding: 13px;
        min-height: 47px;
        border-radius: 10px;
        min-width: 190px;
        font-size: 15px;
        line-height: 140%;
        color: $a2;
        width: 100%;
        text-align: center;
        font-weight: 500;
        letter-spacing: 1.1px;
        &:hover {
          background-color: $a2;
          color: $w;
        }
      }
    }
    &-send {
      &-form {
        box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.1);
        padding-top: 6px;
        padding-right: 18px;
        padding-bottom: 6px;
        padding-left: 6px;
        @include flex(sc);
      }
      &-input {
        width: 100%;
        border: none;
        @include mobile-sm {
          margin: 0;
        }
      }
      &-btn {
        border: none;
        padding: 0;
        &:hover {
        }
      }
      &-sendbtn {
        margin-left: 18px;
      }
    }
    //.widget__chat-details{}
    &-details {
      width: 100%;
    }
    //.widget__chat-summary{}
    &-summary {
      display: block;
      width: 100%;
      cursor: pointer;
      &::-webkit-details-marker {
        display: none;
      }
    }
  }
  &__form {
    //.widget__form-wrap{}
    &-wrap {
      margin-top: 10px;
    }
    //.widget__form-input{}
    &-input {
      // margin-bottom: 10px;
      border: 2px solid $pil;
      &:hover {
        border: 2px solid $a2;
      }
    }
    //.widget__form-btn-wrap{}
    &-btn-wrap {
      width: max-content;
      margin-left: auto;
      margin-top: 10px;
    }
  }
}

.topbtn {
  &__wrap {
    // display: flex;
    // flex-direction: column;
    position: fixed;
    bottom: 10px;
    right: 120px;
    width: max-content;
  }
  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  &__icon {
    transform: rotate(180deg);
    color: $a;
  }
  &__text {
    margin: 0;
    text-transform: uppercase;
    color: $a;
  }
}
