@import '../../../styles/variables.scss';
@import '../../../styles/fonts/index.scss';

.input {
  --gx-input-border-color: var(--gx-color-lightpink);
  --icon-width: 16px;
    border-color: $dg;
    border-style: solid;
    border-width: 2px;
    border-radius: 6px;
    height: 56px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 7px;
    &:focus-within {
      border-radius: 8px;
      border-color: $dg2;
    }

  &::part(label) {
    color: var(--gx-color-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.04em;
  }
  &::part(input) {
    @extend %font;
    font-size: 14px;
    height: 54px;

    &::placeholder {
      color: $dg2;
      opacity: 1;
    }
  }
}
.input-mt_20 {
  margin-bottom: 20px;
}
.error {
  &::part(base) {
    border: 2px solid rgba(233, 60, 60, 0.75);
    box-sizing: border-box;
    border-radius: 6px;
    &:focus-within {
      border-radius: 4px;
      border-color: $dg2;
    }
  }
}
