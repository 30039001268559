.black_full_width {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  color: var(--gx-color-white);
  background: var(--gx-color-primary);
  &:hover {
    color: var(--gx-color-white);
    box-shadow: 0px 26px 18px rgba(0, 0, 0, 0.2);
  }
}