@import '../../../styles/variables.scss';
.btn {
  --gx-transition-fast: 0.3s;
  --gx-input-height-medium: auto;
  &::part(label) {
    color: var(--gx-color-primary);
    @include mobile {
      margin-top: 15px;
    }
  }
  // &::part(base) {
  //   position: relative;
  //   padding-top: 15px;
  //   padding-bottom: 15px;
  //   outline: none;
  //   font-weight: bold;
  //   font-size: 14px;
  //   color: var(--gx-color-white);
  //   letter-spacing: 1px;
  //   align-items: center;
  //   background: var(--gx-color-white);
  //   box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.06);
  //   border-radius: 6px;
  //   border: none;
  //   overflow: hidden;
  //   @include mobile {
  //     flex-direction: column;
  //   }
  //   @include mobile-md {
  //     padding-left: 5px;
  //     padding-right: 5px;
  //   }

  //   &:hover {
  //     background: var(--gx-color-palePurple);
  //     box-shadow: 0px 0px 0px 2px var(--gx-color-accent2);
  //     box-sizing: border-box;
  //     border-radius: 6px;
  //   }
  //   &:focus {
  //     outline: none;
  //     box-shadow: 0px 26px 18px rgba(0, 0, 0, 0.2);
  //   }
  //   &:active {
  //     outline: none;
  //     box-shadow: 0px 26px 18px rgba(0, 0, 0, 0.2);
  //   }
  // }
}
