@import '../../styles/variables.scss';
@import '../../styles/fonts/index.scss';

.poppins {
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  margin-bottom: 15px;
  @include mobile {
    margin: 0 15px 10px;
  }
}
.product__instock {
  &-title {
    @extend %font-title;
    text-align: center;
    font-style: normal;
    font-weight: 900;
    font-size: 38px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: $p;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
//.cooperation-title{}
.cooperation {
  // &-title {
    // @extend %font-title;
  //   text-align: center;
  //   font-style: normal;
  //   font-weight: bold;
  //   font-size: 38px;
  //   letter-spacing: 0.08em;
  //   text-transform: uppercase;
  //   color: var(--gx-color-primary);
  //   margin-bottom: 20px;
  //   margin-top: 0px;
  //   padding-top: 120px;
  //   @include tablet-md {
  //     font-size: calc(12px + 5vw);
  //   }
  // }
}
//.news-title{}
.news {
  &-title {
    @extend %font-title;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: $p;
    margin-bottom: 20px;
    margin-top: 20px;
    @include tablet-md {
      text-align: center;
    }
  }
}
.live__photos {
  &-title {
    @extend %font-title;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: $p;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
.reviews {
  &-title {
    @extend %font-title;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: $p;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.text__center{
  text-align: center;
}


.page {
  // .page__news{}
  &__news {
    @extend %font-title;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    font-size: 54px;
    line-height: 130%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $p;
    margin-top: 30px;
    margin-bottom: 30px;
    // page__news-pink{}
    &-pink {
      @extend %font-title;
      font-style: normal;
      text-align: center;
      font-weight: 600;
      font-size: 54px;
      line-height: 130%;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: $pil;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

.catalog-heading {
  @include h(2);
  @extend %font-title;
  text-transform: uppercase;
  font-size: 36px;
  // margin-bottom: 15px;

}
.already-watched {
  @extend %font;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 140%;
  align-items: center;
  letter-spacing: 0.02em;
  color: $p;
}

.information-payments__title {
  text-align: center;
  padding-top: 38px;
  padding-bottom: 38px;
  padding-left: 20px;
  padding-right: 20px;
  @extend %font-title;
  font-style: normal;
  font-weight: 600;
  // font-size: 44px;
  @include adaptive-value('font-size',44,33,px,2);
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $p;
  @include mobile-lg {
    font-size: 36px;
    padding: 0;
  }
}

.wishlist {
  text-align: left;
  // padding-top: 60px;
  padding-right: 20px;
  @extend %font-title;
  font-style: normal;
  font-weight: 600;
  // font-size: 44px;
  @include adaptive-value('font-size',44,33,px,2);
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $p;
  @include laptop-sm {
    padding: 0 15px;
  }
}

.cart-min {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: $pi;
  position: relative;
}
.cart {
  @include h(2);
  @extend %font;
  color: $p;
  text-align: left;
  padding-right: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 130%;
  letter-spacing: .04em;
  text-transform: uppercase;
}

.cart-order{
  @include h(2);
  text-transform: uppercase;

  color: $p;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  font-family: Noto Sans;
  text-align: center;
  margin-top: 20px;
}

.order{
  font-size: 25px;
}
.lk-message{
  font-size: 20px;
  font-weight: 400;
  padding: 0 5px;
}

.news-details-page__title {
  @extend %font-title;
  font-style: normal;
  font-weight: 600;
  // font-size: 44px;
  @include adaptive-value('font-size',44,33,px,2);
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: center;
  padding: 10px 0px;
  color: $p;
  // word-break: break-word;
}

.cabinet__heading {
  @include h(3);
  @include tablet-md {
    font-size: 36px;
    margin-left: 15px;
  }
}
.about-page {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: $a;
}

.about_page_for__head {
  margin-bottom: 58px;
}

.about_page_pluses__head {
  text-align: center;
  margin-bottom: 20px;
}

.about__card_head {
  @extend %cab-h1;
  text-align: center;
}
.prodpage__title {
  @extend %cab-h1;
  line-height: 150%;
}
.productdescription__title {
  @extend %font;
  font-size: 32px;
  letter-spacing: 0.02em;
  // @include padding-for-mobile;
}
.min_card {
  @extend %font;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $p;
}

.sub_content {
  @extend %font-title;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $p;
}

.cabinet_orders_details__title {
  @include h(4);
  color: $a2;
  margin-bottom: 20px;
  @include mobile {
    display: flex;
    flex-direction: column;
    word-wrap: anywhere;
    @include adaptive-value('font-size',26,21,px,2);
  }
}

.promocode {
  &-create {
    // font-family: Noto Sans;
    @extend %font;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: $p;
  }
}
