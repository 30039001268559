@import '../../../styles/variables.scss';
@import '../../../styles/fonts/index.scss';

.spinner {
  //.spinner__wrapper{}
  &__wrapper {
    width: 100%;
    margin-top: 30px;
    height: var(--height-block-spinner);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 100%;
     //.cabinet-orders--loadding{}
    &--loadding{
      width: 100%;
      height: 100%;
      background-color: rgba(18, 18, 18, 0.05);
      position: absolute;
      min-height: 100px;
      top: 0;
      display: flex;
      align-items: center;
    }
  }
  //.spinner__center{}
  &__center{
    margin: 0 auto;
    top: 20%;
    transform: translateY(-50%);
    position: relative;
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  //.spinner__wrapper-center-margen{}
  &__wrapper-center-margen{
    margin: 50px auto;
    display: flex;
    justify-content: center;
  }

}

.empty_catalog {
  &-wrapper {
    width: 100%;
    height: 300px;
  }
}
