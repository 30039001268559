@import '../../../styles/fonts/index.scss';
@import '../../../styles/variables.scss';
.live_photos {
  &-text {
    @extend %font;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    padding-left: 70px;
    padding-right: 70px;
    margin-bottom: 60px;
    color: #282534;
    @include padding-for-mobile;
  }
  &-details-text {
    @extend %font;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    padding-left: 70px;
    padding-right: 70px;
    margin-bottom: 20px;
    color: #282534;
    @include padding-for-mobile;
  }
}
//.text-content__cart-info{
.text-content{
  &__cart-info{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--gx-color-primary);
    margin: 15px 0;
    @include padding-for-mobile;
  }
  
  //.text-content__order-info{}
    &__order-info{
    @extend %font;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    text-align: justify;
    margin-bottom: 20px;
    color: #282534;
    }
}
