$w: var(--gx-color-white);
$b: var(--gx-color-blue);
$lm: var(--gx-color-lightmauve);
$p: var(--gx-color-primary);
$p2: var(--gx-color-primary2);
$a: var(--gx-color-accent);
$a2: var(--gx-color-accent2);
$pi: var(--gx-color-pink);
$pi2: var(--gx-color-pink2);
$pi3: var(--gx-color-pink3);
$pil: var(--gx-color-lightpink);
$pilr: var(--gx-color-lightpink-rgb);
$dg: var(--gx-color-darkgray);
$dg2: var(--gx-color-darkgray2);
$rd: var(--gx-color-red);
$gr: var(--gx-color-green);
$id: var(--gx-color-darkgray2);
$back: var(--gx-color-black);
$g: var(--gx-color-gray);
$g2: var(--gx-color-gray2);
$g4: var(--gx-color-gray4);
$fontLayout: #ffffff;
$minWidth: 320px;
$maxWidth: 1920;
$maxWidthContainer: 1400;
$fontSize: 16px;//16px

    
// @include adaptive-value('width',400,150,px,2)
//

/* миксин для адаптивного изменения свойств */
/* $property -- название свойства которое будит изменятся например padding*/
/* $startSize - начальный размер по задоном расширении по макету */
/* $minSize - конечный размер до какого уменьшится при изменениях экрана до 320px */
/* $type - параметр условия по которому выполнятся миксину*/
/* $measurements - система измерения без ковычек*/

@mixin adaptive-value($property, $startSize, $minSize, $measurements, $type) {
	$addSize: $startSize - $minSize;
	@if $type == 2 {
		//только если меньше контейнера
		#{$property}: $startSize + $measurements;
		@media (max-width: #{$maxWidthContainer + $measurements}) {
			#{$property}: calc(#{$minSize + $measurements} + #{$addSize} * ((100vw - 320px) / #{$maxWidthContainer - 320}));
		}
	} @else if $type == 1 {
		//только больше контейнера
		#{$property}: $startSize + $measurements;
		@media (min-width: #{$maxWidthContainer + $measurements}) {
			#{$property}: calc(#{$minSize + $measurements} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}));
		}
	} @else {
		//всегда
		#{$property}: calc(#{$minSize + $measurements} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}));
	}
}

@mixin adaptive-font($minSize, $maxSize, $measurements: px ){
	$addedValue: $maxSize - $minSize;
	$offsetValue: $addedValue + $addedValue * 0.7;

	@media (max-width: 767px) {
		font-size: calc(#{$maxSize + $measurements} + #{$offsetValue} * ((100vw - 320px) / #{$maxWidth}));
	}
	@media (min-width: 767.1px) {
		font-size: calc(#{$maxSize + $measurements} + #{$addedValue} * (100vw / #{$maxWidth}));
	}
}

// ************************шрифт размер адаптив****************

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function calcFluidFontSize($f-min, $f-max, $w-min, $w-max, $units: px) {
  $f-min: strip-unit($f-min);
  $f-max: strip-unit($f-max);
  $w-min: strip-unit($w-min);
  $w-max: strip-unit($w-max);
  
	$k: ($f-max - $f-min)/($w-max - $w-min);
	$b: $f-min - $k * $w-min;

	$b: $b + $units;

	@return calc( #{$k} * 100vw + #{$b} );
}

// миксин адаптивного шрифта

// adaptiv-font(миним размер, макс размер по шаблону, )
@mixin adaptiv-font($f-min, $f-max, $w-min: 320px,$w-max: 1920px, $fallback: false) {
  
  font-size: $f-min;
  
  @media (min-width: $w-min) {
    @if ($fallback) {
      font-size: $fallback;
    }
    font-size: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);  
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}
// **********************************************************************
@mixin row($mr) {
  display: flex;
  flex-wrap: wrap;
  margin-left: -#{$mr}px;
  margin-right: -#{$mr}px;
}

@mixin row-flex() {
  display: flex;
  flex-wrap: wrap;
}
// миксин разбиение ширины на 12 частей где 
// $colsize на сколько разбиваем
// $mrg марджен по краям 
@mixin col($colsize, $mrg) {
  width: calc(100% / 12 * #{$colsize} - #{$mrg * 2}px - 0.1px);
  margin-left: #{$mrg}px;
  margin-right: #{$mrg}px;
  word-wrap: break-word;
}

@mixin coloff($coloffset, $mrg2) {
  margin-left: calc(100% / 12 * #{$coloffset} + #{$mrg2}px);
}

%container {
  margin: 0 auto;
  width: 100%;
  max-width: $maxWidthContainer + px;
}

@mixin ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin flex($type) {
  display: flex;
  @if ($type == 'lc') {
    justify-content: flex-start;
    align-items: center;
  }
  @if ($type == 'sc') {
    justify-content: space-between;
    align-items: center;
  }
  @if ($type == 'rc') {
    justify-content: flex-end;
    align-items: center;
  }
  @if ($type == 'cc') {
    justify-content: center;
    align-items: center;
  }
  @if ($type == 'ct') {
    justify-content: center;
    align-items: flex-start;
  }
  @if ($type == 'lt') {
    justify-content: flex-start;
    align-items: flex-start;
  }
  @if ($type == 'st') {
    justify-content: space-between;
    align-items: flex-start;
  }
  @if ($type == 'rt') { 
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@mixin flexcol($type: 't', $wrap: 'w') {
  display: flex;
  flex-direction: column;
  @if ($type == 't') {
    justify-content: flex-start;
  }
  @if ($type == 's') {
    justify-content: space-between;
  }
  @if ($wrap == 'w') {
    flex-wrap: wrap;
  }
  @if ($type == 'n') {
    flex-wrap: nowrap;
  }
}

@mixin laptop-lg {
  @media screen and (max-width: 1600px) {
    @content;
  }
}

@mixin laptop-md {
  @media screen and (max-width: 1440px) {
    @content;
  }
}

@mixin laptop-min-md {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

@mixin laptop-sm {
  @media screen and (max-width: 1366px) {
    @content; 
  }
}

@mixin laptop-lgm {
  @media screen and (max-width: 1280px) {
    @content; 
  }
}

@mixin tablet-lgl {
  @media screen and (max-width: 1080px) {
    @content;
  }
}

@mixin tablet-lg {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin tablet-mmd {
  @media screen and (max-width: 960px) {
    @content;
  }
}

@mixin tablet-mmdl {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin tablet-md {
  @media screen and (max-width: 768.1px) {
    @content;
  }
}

@mixin tablet-min-md {
  @media screen and (min-width: 768.1px) {
    @content;
  }
}

@mixin mobile-min {
  @media screen and (min-width: 680px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 680px) {
    @content;
  }
}

@mixin mobile-mmlg {
  @media screen and (max-width: 620px) {
    @content;
  }
}

@mixin mobile-mlg {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin mobile-lgm {
  @media screen and (max-width: 425px) {
    @content;
  }
}

@mixin mobile-lg {
  @media screen and (max-width: 375px) {
    @content;
  }
}

@mixin mobile-md {
  @media screen and (max-width: 360px) {
    @content;
  }
}

@mixin mobile-sm {
  @media screen and (max-width: 320px) {
    @content;
  }
}

@mixin laptop-old {
  @media screen and (max-height: 810px) and (min-width: 1150px) {
    @content;
  }
}

// COMPONENTS MIXINS

@mixin categoryCard {
  .category-card {
    display: flex;
    flex-direction: column;
    padding: 5px;
    transition: 0.3s;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    min-height: 100%;

    &:hover {
      background-color: var(--gx-color-gray2);
      .category-card__img {
        transform: scale(1.1);
      }
    }
    &__image {
      // margin-bottom: 22px;
      position: relative;
      overflow: hidden;
      @include adaptive-value('min-height',500,150,px,1);
      // min-height: 500px;
      @include adaptive-value('max-height',634,300,px,1);

      // max-height: 634px;

      @include mobile {
        min-height: auto;
      }

      img {
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-color: var(--gx-color-accent);
          opacity: 0.3;
        }
      }
    }
    &__inner-title {
      flex: 1 1 auto;
      display: flex;
      align-items: flex-end;
      justify-content: center;

    }
    &__img {
      display: block;
      object-fit: cover;
      transition: 0.4s;
    }
    &>h2 {
      padding: 0 0 15px 0 ;
      // letter-spacing: 1.9px;
    }
  }
}

@mixin tableStyle {
  %cab-h2 {
    @extend %font;
    font-weight: bold;
    font-size: 16px;
  }
  %text-small {
    @extend %font;
    font-size: 12px;
    letter-spacing: 0.3px;
  }
  display: block;

  width: 100% !important;
  overflow-x: scroll;
  max-width: 100%;
  border-collapse: 0;
  border-spacing: 0;
  tr {
    border-bottom: 5px solid transparent;
    &:hover {
      background-color: var(--gx-color-gray2);
      & a {
        text-decoration: underline;
      }
    }
  }
  th,
  td {
    padding: 11px 0 11px 20px;
  }
  th {
    background: var(--gx-color-accent2);
    color: var(--gx-color-white);
    font-weight: bold;
    text-align: left;
    @extend %cab-h2;
    border-right: 2px solid var(--gx-color-white);
    padding: 10px;
    &:last-child {
      border-right: 0;
    }
  }
  &__tdfull {
    padding: 10px 0 !important;
  }
  &__th {
    position: relative;
  }
  &__thlink {
    opacity: 0.5;
    --icon-width: 16px;
    position: absolute;
    top: -8px;
    right: -20px;

    &:hover {
      opacity: 1;
    }
  }
}

@mixin tabs {
  @include ul;
  @include flex(cc);
  border: 2px solid var(--gx-color-primary2);
  border-radius: 2px;
  overflow-x: auto;
}

@keyframes slidertimer {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@mixin padding-for-mobile {
  @include laptop-md {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include mobile {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@mixin scrollbar {
  scrollbar-color: #ffa6b0 #fcd2d7;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent; /* «цвет полосы скроллбара» */
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $pi; /* «цвет ползунка» */
    :hover{
      cursor: pointer;
    }
  }
 
}


//?! нужно проверить

// scrollbar-color: #ffa6b0 #fcd2d7;

/* «цвет ползунка» «цвет полосы скроллбара» */
// scrollbar-width: auto | thin | none;  /* толщина */
// ::-webkit-scrollbar {
//   height: 12px;
//   width: 12px;
//   background: #ffa6b0 #fcd2d7;
// }

// ::-webkit-scrollbar-thumb {
//   background: #ffa6b0 #fcd2d7;
//   -webkit-border-radius: 1ex;
//   -webkit-box-shadow: 0px 1px 2px rgba(213, 127, 206, 0.75);
// }

// ::-webkit-scrollbar-corner {
//   background: #ffa6b0 #fcd2d7;
// }

// ::-webkit-scrollbar {
//   width: 11px;
//   height: 11px;
// }

// ::-webkit-scrollbar-button {
//   width: 0px;
//   height: 0px;
// }

// ::-webkit-scrollbar-thumb {
//   background: #ffa6b0;
//   border: 1px solid#ffa6b0;
//   border-radius: 50px;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background: #ffa6b0;
// }

// ::-webkit-scrollbar-thumb:active {
//   background: #ffa6b0;
// }

// ::-webkit-scrollbar-track {
//   background: #ffa6b0;
//   border: 0px none #ffffff;
//   border-radius: 53px;
// }

// ::-webkit-scrollbar-track:hover {
//   background: #666666;
// }

// ::-webkit-scrollbar-track:active {
//   background: #333333;
// }

// ::-webkit-scrollbar-corner {
//   background: transparent;
// }

