@import '../../../../styles/variables.scss';

.info-pay{
    //.info-pay__wrapper{}
    &__wrapper{
        padding: 0;
        padding: 0 5px;
        @include mobile {
            margin-left: 16px;
        }
    }
    //.info-pay__text{}
    &__text{
        padding: 5px 0;

        //.info-pay__text--grey{}
        &--grey{
            color: $dg2;
            font-size: 12px;
            padding: 0 0 5px 0;
            display: flex;
        }
        //.info-pay__text--green{}
        &--green{
            color: rgb(0, 188, 0);
            font-size: 12px;
            // padding: 0 0 5px 0;
            margin-left: 7px;
        }
        //.info-pay__text--red{}
        &--red{
            color: rgb(255, 0, 0);
            font-size: 12px;
            // padding: 0 0 5px 0;
            margin-left: 7px;
        }
    }
}