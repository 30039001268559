@import '../../../styles/fonts/index.scss';
@import '../../../styles/variables.scss';


.cabinet-table--history {
  width: 100%;
  border-collapse: 0;
  border-spacing: 0;
  @include scrollbar;
  overflow-x: auto;
  // display: block;
  tbody>tr {
    border-bottom: 5px solid transparent;
    &:hover {
      background-color: var(--gx-color-gray2);
      a {
        text-decoration: underline;
      }
    }
    td > span{
        height: 60px !important;
    }
    td>a{
      color: $a2;
      &:hover{
        color: $a;
        cursor: pointer;
        transform: scale(1.4);
      }
    }
    td>button{
      color: $a;
      border: 1px solid $a;
      padding: 7px;
      border-radius: 2px;
      &:hover{
        color: $a2;
        cursor: pointer;
        transform: scale(1.1);
        border: 1px solid $a2;
      }
    }
  }
  th,
  td {
    padding: 11px;
  }
  th {
    background: var(--gx-color-accent2);
    color: var(--gx-color-white);
    font-weight: bold;
    text-align: center;
    @extend %cab-h2;
    border-right: 2px solid var(--gx-color-white);
    &:last-child {
      border-right: 0;
    }
  }
  &__tdfull {
    padding: 10px 0 !important;
  }
  &__th {
    position: relative;
  }
  &__thlink {
    opacity: 0.5;
    --icon-width: 16px;
    position: absolute;
    top: -8px;
    right: -20px;

    &:hover {
      opacity: 1;
    }
  }
}
.cabinet-table {
  width: 100%;
  border-collapse: 0;
  border-spacing: 0;
  @include scrollbar;
  overflow-x: auto;
  // display: block;
  tbody>tr {
    border-bottom: 5px solid transparent;
    &:hover {
      background-color: var(--gx-color-gray2);
      a {
        text-decoration: underline;
      }
    }
    td>a{
      color: $a2;
      &:hover{
        color: $a;
        cursor: pointer;
        transform: scale(1.4);
      }
    }
    td>button{
      color: $a;
      border: 1px solid $a;
      padding: 7px;
      border-radius: 2px;
      &:hover{
        color: $a2;
        cursor: pointer;
        transform: scale(1.1);
        border: 1px solid $a2;
      }
    }
  }
  th,
  td {
    padding: 11px;
  }
  th {
    background: var(--gx-color-accent2);
    color: var(--gx-color-white);
    font-weight: bold;
    text-align: center;
    @extend %cab-h2;
    border-right: 2px solid var(--gx-color-white);
    &:last-child {
      border-right: 0;
    }
  }
  &__tdfull {
    padding: 10px 0 !important;
  }
  &__th {
    position: relative;
  }
  &__thlink {
    opacity: 0.5;
    --icon-width: 16px;
    position: absolute;
    top: -8px;
    right: -20px;

    &:hover {
      opacity: 1;
    }
  }
}

.clients-table {
  width: 100%;
  border-collapse: 0;
  border-spacing: 0;
  font-size: 12px;
  tr {
    border-bottom: 5px solid transparent;
    &:hover {
      background-color: var(--gx-color-gray2);
      & a {
        text-decoration: underline;
      }
    }
  }
  th,
  td {
    padding: 11px 0 11px 20px;
  }
  th {
    background: var(--gx-color-accent2);
    color: var(--gx-color-white);
    font-weight: bold;
    text-align: left;
    @extend %cab-h2;
    border-right: 2px solid var(--gx-color-white);
    &:last-child {
      border-right: 0;
    }
  }
  &__tdfull {
    padding: 10px 0 !important;
  }
  &__th {
    position: relative;
  }
  &__thlink {
    opacity: 0.5;
    --icon-width: 16px;
    position: absolute;
    top: -8px;
    right: -20px;

    &:hover {
      opacity: 1;
    }
  }
  &-small {
    @extend %text-small;
  }
  &-gray {
    @extend %text-small;
    color: rgba(29, 27, 30, 0.5);
  }
  &-name {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.04em;
    color: var(--gx-color-accent2);
  }
  &__name {
    min-width: 150px;
  }
  &__address {
    min-width: 185px;
  }
  &__phone {
    min-width: 105px;
  }
  &__date {
    min-width: 185px;
  }
  &__balance {
    min-width: 200px;

    &_wrapper,
    &_left,
    &_right {
      width: 100%;
      border-radius: 12px;
      display: flex;
      align-items: center;
    }

    &_wrapper {
      background-color: var(--gx-color-gray);

      justify-content: space-between;
    }
    &_left {
      background-color: var(--gx-color-accent);
      // display: flex;
      justify-content: center;
      // align-items: center;
      padding: 4px;
      color: var(--gx-color-lightpink);
    }
    &_right {
      color: var(--gx-color-green);
      justify-content: center;
      padding: 4px;
    }
  }
}

.market-table {
  width: 100%;
  border-collapse: 0;
  border-spacing: 0;
  tr {
    border-bottom: 5px solid transparent;
    &:hover {
      background-color: var(--gx-color-gray2);
      & a {
        text-decoration: underline;
      }
    }
  }
  th,
  td {
    padding: 10px;
  }
  th {
    background: var(--gx-color-accent2);
    color: var(--gx-color-white);
    font-weight: bold;
    text-align: left;
    @extend %cab-h2;
    border-right: 2px solid var(--gx-color-white);
    &:last-child {
      border-right: 0;
    }
  }
  &__tdfull {
    padding: 10px 0 !important;
  }
  &__th {
    position: relative;
  }
  &__thlink {
    opacity: 0.5;
    --icon-width: 16px;
    position: absolute;
    top: -8px;
    right: -20px;

    &:hover {
      opacity: 1;
    }
  }
}

.information-howto {
  &-table {
    @extend %text-small;
    border-spacing: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gx-color-gray);
    table-layout: fixed;
    width: 100%;
    thead {
      background-color: rgba(243, 239, 243, 0.3);
      height: 56px;
    }

    th,
    td {
      width: 100%;
    }

    td {
      text-transform: uppercase;
    }

    tr {
      height: 56px;
      text-align: center;
    }

    th {
      font-weight: normal;
    }
  }
  &-small {
    &-table {
      @extend %text-small;
      border-spacing: 0;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--gx-color-gray);
      table-layout: fixed;
      width: 100%;
      thead {
        background-color: rgba(243, 239, 243, 0.3);
        height: 56px;
      }

      th,
      td {
        width: 100%;
        font-size: 12px;
        white-space: nowrap;
      }

      td {
        // text-transform: uppercase;
        &:first {
          width: 147px;
        }
      }

      tr {
        height: 56px;
        text-align: center;
        &:hover {
          background-color: var(--gx-color-pre-white);
        }
        &:nth-child(2n-1) {
          background-color: var(--gx-color-pre-white);
        }
      }

      th {
        font-weight: normal;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .information-howto {
    &-table {
      width: auto;
      table-layout: auto;

      th,
      td {
        width: auto;
      }
    }
  }
  .information-howto {
    &-small-table {
      width: auto;
      table-layout: auto;
      th,
      td {
        width: auto;
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .information-howto {
    &-table {
      width: calc(100% - 5px);
      margin: 5px;
      thead {
        display: none;
      }
      tr {
        height: auto;
        margin-bottom: 20px;
        display: block;
        border-bottom: 2px solid #ddd;
      }
      tr:last-child {
        margin-bottom: 0;
      }
      td {
        display: block;
        text-align: right;
        border-bottom: 1px dotted #ccc;
        border-right: 1px solid transparent;
      }
      td:last-child {
        border-bottom: 0;
      }
      td:before {
        content: attr(data-label);
        float: left;
        text-transform: none;
        font-weight: normal;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .cabinet-table,
  .market-table {
    // width: 100%;
    width: calc(100%);
    display: block;    
    thead {
      display: none;
    }
    tbody{
      display: block;
    }
    tr {
      height: auto;
      margin-bottom: 20px;
      display: block;
      border-bottom: 2px solid #ddd;
    }
    tr:last-child {
      margin-bottom: 0;
    }
    td {

      padding: 11px 15px;
      display: block;
      text-align: right;
      border-bottom: 1px dotted #ccc;
      border-right: 1px solid transparent;
      overflow: hidden;
      // высота - костыль. при пустом значении плывет верстка
      min-height: 42px;
      & gx-button {
        display: block;
      }
    }
    td:last-child {
      border-bottom: 0;
    }
    td:before {
      content: attr(data-label);
      float: left;
      text-transform: none;
      font-weight: normal;
      font-weight: bold;
    }
  }
  .clients-table {
    width: 100%;
    thead {
      display: none;
    }
    tr {
      height: auto;
      margin-bottom: 20px;
      display: block;
      border-bottom: 2px solid #ddd;
    }
    tr:last-child {
      margin-bottom: 0;
    }
    td {
      padding: 11px 15px;
      display: block;
      text-align: right;
      border-bottom: 1px dotted #ccc;
      border-right: 1px solid transparent;
      // высота - костыль. при пустом значении плывет верстка
      min-height: 42px;
      & gx-button {
        display: block;
      }
    }
    td:last-child {
      border-bottom: 0;
    }
    td:before {
      content: attr(data-label);
      float: left;
      text-transform: none;
      font-weight: normal;
      font-weight: bold;
    }
  }
}

.small {
  text-align: left;
  width: 147px !important;
  padding-left: 5px;
}
