@import '../variables.scss';

@font-face {
  font-family: 'Noto Sans';
  src: url('./NotoSans-Bold.woff2') format('woff2'), url('./NotoSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('./NotoSans.woff2') format('woff2'), url('./NotoSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-Regular.woff2') format('woff2'), url('./Manrope-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-Medium.woff2') format('woff2'), url('./Manrope-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-Semibold.woff2') format('woff2'), url('./Manrope-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cormorant';
  src: url('./Cormorant-SemiBold.woff2') format('woff2'), url('./Cormorant-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cormorant Garamond';
  src: url('./CormorantGaramond-Regular.woff2') format('woff2'),
    url('./CormorantGaramond-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cormorant Garamond';
  src: url('./CormorantGaramond-Bold.woff2') format('woff2'),
    url('./CormorantGaramond-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
// ***********************************************************************

%font {
  // font-family: 'Noto Sans', sans-serif;
  font-family: 'Noto Sans';
}
%font-head {
  // font-family: 'Cormorant Garamond', sans-serif;
  font-family: 'Cormorant Garamond';
}
%font-title {
  // font-family: 'Cormorant Garamond', sans-serif;
  font-family: 'Cormorant Garamond';
}
%heading {
  font-weight: bold;
  @extend %font-head;
  margin: 0;
  // letter-spacing: 10px;
}
%cab-h1 {
  @extend %font;
  font-weight: bold;
  font-size: 22px;
}
%cab-h2 {
  @extend %font;
  font-weight: bold;
  font-size: 16px;
}
%cab-h3 {
  @extend %font;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}
%cab-h4 {
  @extend %font;
  font-weight: bold;
  font-size: 14px;
}
%text-small {
  @extend %font;
  font-size: 12px;
  letter-spacing: 0.3px;
}
%text-btn {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  @extend %font;
}
%text-link {
  font-size: 12px;
  letter-spacing: 0.5px;
  @extend %font;
}
%text-18 {
  font-size: 18px;
  font-weight: normal;
  @extend %font;
}
%text-20 {
  font-size: 20px;
  font-weight: normal;
  @extend %font;
}
%text-24 {
  font-size: 24px;
  font-weight: normal;
  @extend %font;
}

@mixin h($s) {
  @if $s == 1 {
    @extend %heading;
    font-size: 44px;

    @include mobile {
      font-size: 32px;
    }
  } @else if $s == 2 {
    @extend %heading;
    font-size: 38px;
    font-weight: bold;

    @include mobile {
      font-size: 28px;
    }
  } @else if $s == 3 {
    @extend %heading;
    font-size: 32px;
    font-weight: bold;

    @include mobile {
      font-size: 26px;
    }
  } @else if $s == 4 {
    @extend %font;
    font-weight: bold;
    font-size: 32px;

    @include mobile {
      font-size: 26px;
    }
  } @else if $s == 5 {
    @extend %font;
    font-size: 18px;

    @include mobile {
      font-size: 16px;
    }
  } @else {
  }
}


