@import '../../../styles/variables.scss';

.btn {
    position: relative;
    @include flex('cc');
    padding-top: 20px;
    padding-bottom: 16px;
    outline: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: var(--gx-color-primary2);
    line-height: 140%;
    letter-spacing: .1em;
    transition: all .3s ease-in;
    border: none;
    background-color: transparent;
    &:hover,
    &:active,
    &:focus {
        background: var(--gx-color-white);
        box-shadow: none;
    }
    &:after {
        position: absolute;
        content: '';
        height: 4px;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: var(--gx-color-primary2);
    }
}