.icons {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.icon-left{
    margin-left: 10px;
}

.icon-right{
    margin-right: 10px;
}

.absolute-center{
    position: absolute;
    left: 50%;
    margin-top: -50px;
}

.eye{
    padding: 0;
    &:hover{
        cursor: pointer;
    }
}

.disabled{
    opacity: .5;
    pointer-events: none;
    &:hover{
      cursor: not-allowed;
      box-shadow: none;
    }
  }