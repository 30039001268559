@import '../../styles/variables.scss';
@import '../../styles/fonts/index.scss';

.footer-menu {
  &__title {
    @include h(3);
    font-size: 24px;
    margin-bottom: 20px;
    color: #8f8c98;
  }
  //.footer-menu__list{}
  &__list {
    @include ul;
    @include mobile{

    }
    &-item {
      font-size: 16px;
      // margin-bottom: 20px;

      margin-bottom: 10px;
      color: $a;
      &--active{
        font-size: 16px;
        margin-bottom: 10px;
        pointer-events: none;
        &:hover {
          cursor: not-allowed;
        }
        a{
          color: #4e4e50;
          
        }  
      }
      a{
        color: var(--gx-color-accent);
        &:hover {
          color: var(--gx-color-accent2);
        }
      }

      &:last-child {
        margin: 0;
      }
    }
    @media screen and (max-width: 992px) {
          // display: flex;
      // justify-content: space-around;
         display: grid;
          grid-template-columns: repeat(4, 1fr);
          text-align: left;
          margin-left: calc(57px + 40 * ((100vw - 320px) / 992));
        }
    @media screen and (max-width: 642px) {
      margin-left: calc(12px + 40 * ((100vw - 320px) / 642));
    }

    @media screen and (max-width: 561px) {
      flex-direction: column;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      text-align: left;
      margin-left: calc(18px + 187 * ((100vw - 320px) / 560));
    }
    
    @include mobile {
      // align-items: center;
    }
  }
}
