@import '../../../styles/variables.scss';

.btn {
    width: 100%;
    height: 100%;
    font-size: 22px;
    color: $dg2;
    &:hover{
        color: $a2;
    }
}