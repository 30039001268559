@import '../../../styles/variables.scss';

.restore-password {
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    letter-spacing: 0.02em;
  }
  &__desc {
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.04em;
    color: #8692a6;
  }
  &__submit-code {
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
    max-width: 350px;
  }
}
