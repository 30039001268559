@import '../../styles/variables.scss';
@import '../../styles/fonts/index.scss';

.files-uploud {

    //.files-uploud__form{}
    &__form {
        background: rgba(243, 239, 243, 0.3);
        padding: 10px 20px 20px;
        width: 100%;

        //.files-uploud__form--full{}
        &--full {
            background: var(--gx-color-white);
        }
    }

    //.files-uploud__form-title{}
    &__form-title {
        @extend %font;
        font-size: 22px;
        line-height: 40px;
        border-bottom: 2px solid $g;
        margin-bottom: 10px;
        font-weight: 700;
    }

    //.files-uploud__form-submit-wrappar{}
    &__form-submit-wrappar {
        @include flex('sc');
        margin-top: 25px;
        @include tablet-md {
            flex-direction: column;
            & > div{
                margin-bottom: 15px;
            }
        }
    }

    //.files-uploud__form-submit-btnwrap{}
    &__form-submit-btnwrap {
        width: min-content;

        @include tablet-md {
            margin-left: 20px;
            justify-content: space-between;
            display: flex;
            min-width: 270px;
            flex-direction: column;
        }
    }

    //.files-uploud__form-submit-btndark{}
    &__form-submit-btndark {
        width: 100%;
        overflow: hidden;
        text-transform: uppercase;
        color: var(--gx-color-white);
        font-weight: bold;
        font-size: 14px;
        background-color: var(--gx-color-primary);
        max-width: 417px;
        width: 142px;
        letter-spacing: 1.4px;
        min-height: 46px;
        max-height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 15px;
        // max-width: min-content; // ломаеться в
    }

    //.files-uploud__form-submit-btn{}
    &__form-submit-btn {
        text-transform: uppercase;
        background-color: transparent;
        color: var(--gx-color-primary);
        border: 2px solid var(--gx-color-primary);
        font-weight: bold;
        font-size: 14px;
        max-width: 417px;
        width: 142px;
        letter-spacing: 1.4px;
        min-height: 46px;
        max-height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    //.files-uploud__form-raiting{}
    &__form-raiting {
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        align-content: center;
        &>:first-child{
            margin-right: 5px;
            font-size: 15px;
            letter-spacing: 1.1px;
            line-height: 160%;
        }
        @include tablet-md {
            margin-bottom: 15px;
        }
    }

    //.files-uploud__form-rating-indicato{}
    &__form-rating-indicato {
        padding: 0;
    }

    //.files-uploud__form-upload{}
    &__form-upload {
        padding: 0;
        display: flex;
        margin: 15px 0;
        position: relative;
        justify-content: space-between;
        // & > ul{
        //     bottom: 32px;
        // }
        @include tablet-md{
            margin-bottom: 15px;
            width: 96%;
        }
    }

    //.files-uploud__form-upload-desc{}
    &__form-upload-desc {
        padding: 0;
        font-size: 14px;
        color: $dg2;
        align-self: center;
    }

    // //.files-uploud__form-upload-list{}
    // &__form-upload-list {
    //     padding: 0;
    //     @include ul;
    //     @include row-flex;
    //     padding: 7px 7px 0;
    // }

    // //.files-uploud__form-upload-item{}
    // &__form-upload-item {
    //     padding: 0;
    //     margin-right: 5px;
    //     min-width: 8px;
    //     max-width: 10px;
    //     &:last-child {
    //         margin: 0;
    //     }
    // }

    // //.files-uploud__form-upload-image{}
    // &__form-upload-image {
    //     padding: 0;
    //     width: 20px;
    // }
    // ------------------------------------------------------

      //.files-uploud__form-upload-list{}
      &__form-upload-list {
        padding: 0;
        padding: 7px 7px 0;
        display: flex;
        position: absolute;
        bottom: 12px;
    }

    //.files-uploud__form-upload-item{}
    &__form-upload-item {
        padding: 0;
        margin-right: 5px;
        position: relative;
        max-height: 45px;
        min-width: 35px;
        min-height: 45px;
        display: flex;
        &:last-child {
            margin: 0;
        }
    }

    //.files-uploud__form-upload-image{}
    &__form-upload-image {
        padding: 0;
        width: 35px;
        object-fit: cover;
    }

    //.files-uploud__form-image--delete{}
    &__form-image--delete{
        padding: 8px;
            position: absolute;
            pointer-events: all;
            cursor: pointer;
            left: -13px;
            top: -13px;
            display: block;
            z-index: 10;
            &::after{
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-color: #0603138f;
                border-radius: 50%;
                z-index: 9;
                position: absolute;
                top: 0;
                right: 0;
            }
    }

    //.files-uploud__form-image-icon--delete{}
    &__form-image-icon--delete{
        padding: 0;
        position: relative;
        z-index: 10;
    }
    // ------------------------------------------------------

    //.files-uploud__form-addprod-image{}
    &__form-addprod-image {
        pointer-events: none;
        padding: 0;
    }

    //.files-uploud__form-addprod-image-wrap{}
    &__form-addprod-image-wrap {
        pointer-events: none;
        padding: 0;
    }

    //.files-uploud__form-upload-button{}
    &__form-upload-button {
        color: var(--gx-color-primary);
        font-size: 15px;
        padding: 0px 5px;
        letter-spacing: 0.4px;
        border: none;
        position: relative;
        background: transparent;
        border-bottom: 1px dashed var(--gx-color-primary);
        margin-bottom: 10px;
        // height: 28px;
        max-width: 86px;
        transform: translate(0px, 6px);
        pointer-events: none;

        &input:hover {
            cursor: pointer;
            color: $a2;
        }
    }
    //.files-uploud__form-upload-button-text{}
    &__form-upload-button-text{
        border: none;
        &:hover{
            cursor: pointer;
        }
    }

    //.files-uploud__form-textarea{}
    &__form-textarea {
        padding: 0;
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 121px;
        font-family: var(--gx-input-font-family);
        font-weight: var(--gx-input-font-weight);
        line-height: var(--gx-line-height-normal);
        letter-spacing: var(--gx-input-letter-spacing);
        background-color: var(--gx-input-background-color);
        // border: solid var(--gx-input-border-width) var(--gx-input-border-color);
        vertical-align: middle;
        transition: var(--gx-transition-fast) color, var(--gx-transition-fast) border, var(--gx-transition-fast) box-shadow;
        cursor: text;
        flex: 1 1 auto;
        color: var(--gx-input-color);
        border-radius: var(--gx-input-border-radius-medium);
        font-size: var(--gx-input-font-size-medium);
        box-shadow: inset 1px 1px 2px 1px $dg, inset -1px -1px 2px 1px $dg;
        padding: 5px;

        &::placeholder {
            color: var(--gx-input-placeholder-color);
            user-select: none;
            padding: 5px;
        }
    }

    //.
}

.cabinet-orders-details{
    padding: 0;
    &>ul.files-uploud__form-upload-list{
        position: absolute;
        pointer-events: none;
        transform: translate(0px, -17px);
        flex-wrap: nowrap;
        right: 0;
    }
}
