@import '../../../styles/variables.scss';

.modal-provider {

    //.modal-provider__close{}
    &__close {
        position: sticky;
        top: 10px;
        left: 95%;
        width: 20px;
        height: 20px;
        border: 1px solid $dg;
        z-index: 10005;
        border-radius: 50%;
        min-width: 20px;
        min-height: 20px;
        @include mobile{
            left: 90%;
        }
        &::after,
        &::before {
            content: '';
            width: 1px;
            height: 100%;
            position: relative;
            display: block;
            background-color: $dg;
        }

        &::after {
            transform: translate(9px, -18px) rotate(45deg);
        }

        &::before {
            transform: translate(8px, 0px) rotate(-45deg);
        }

        &:hover {
            cursor: pointer;
            border: 1px solid $a2;

            &::after {
                background-color: $a2;
            }

            &::before {
                background-color: $a2;
            }
        }
    }

    //.modal-provider__icon{}
    &__icon {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &>div {
            height: 60px;
            width: 70px;
        }
    }

    //.modal-provider__container
    &__container {
        position: relative;
        // width: 100vw;
        // height: 100vh;
        width: auto;
        transition: all .8s ease-in-out;
        overflow: hidden;
        //.modal-provider__container--show{}
        &--show {
            height: 100vh;
            width: 100%;
            position: fixed;

            &::after {
                content: "";
                width: 100%;
                height: 100vh;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 9999;
                position: fixed;
                display: flex;
                left: 0;
                top: 0;
            }
        }
    }

    //.modal-provider__body{}
    &__body {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        transition: opacity .8s;
        z-index: 9999999;
        &>.modal-provider__spinner {
            display: none;
        }

        //.modal-provider__body--show{}
        &--show {
            // overflow-y: auto;
            overflow: hidden;
            opacity: 1;
            visibility: visible;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            z-index: 10001;
            padding: 10px;
            min-width: 50%;
            // max-height: 80vh;
            // width: 80vw;
            // display: flex;
            // flex-direction: column;

            // height: 100%;
            border-radius: 7px;
            transition: opacity .9s, visibility .9s;
            // display: grid;
            grid-template-columns: minmax(320px, 80vw);
            min-height: 250px;
            max-height: 80vh;
            @include laptop-lgm {
                min-width: 60%;
            }
            @include tablet-lg {
                min-width: 70%;
            }
            @include tablet-mmd {
                min-width: 85%;
            }
            @include mobile {
                min-width: 97%;
                width: 97%;
                display: flex;
                flex-direction: column;
            }

            &>.modal-provider__spinner {
                display: block;
            }
        }
    }

    //.modal-provider__title{}
    &__title {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
        flex: 0 0;
        display: flex;
        justify-content: center;
        font-size: 30px;
        text-align: center;
        padding: 0 10px;
        @include mobile{
            // width: calc(100% - 30px);
        }
    }

    //.modal-provider__content{}
    &__content {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        letter-spacing: .8px;
        flex: 1 1;
        position: relative;
        overflow-y: auto;
        margin-bottom: 25px;
        // &>div{
        //     height: 80vh;
        // }
        // text-align: center;
        @include scrollbar;
        @include mobile{
            // width: calc(100% - 30px);
        }
    }

    //.modal-provider__spinner{}
    &__spinner {
        // position: absolute;
        position: relative;
        // left: 33%;
        // top: 20%;
        left: 50%;
        top: 50%;

        transform: scale(2.5) translate(-30%, -20%);
        width: 100px;
        // animation-name: move-cart;
        animation-duration: 17s;
        animation-iteration-count: infinite;
        //.modal-provider__spinner-text{}
        &-text{
            width: 100vw;
            height: 100vh;
            position: relative;
            background-color: rgba(0, 0, 0, 0.7);

            &::after{
                content: 'Ftownpl.com загрузка...';
                font-size: 50px;
                animation: sTransition 10s linear infinite;
                background: linear-gradient(to right,
                    rgb(197, 137, 171),
                    rgb(191, 93, 188),
                    rgb(142, 79, 185),
                    rgb(178, 59, 160),
                    rgb(170, 72, 200),
                    rgb(202, 86, 217),
                    rgb(238, 42, 199),
                    rgb(209, 4, 172)
                );
                background-size: 400%;
                background-clip: text;
                color: rgba(255, 255, 255, .1);
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI';
                font-weight: 700;
                left: 50%;
                position: absolute;
                text-align: center;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        //.modal-provider__spinner-loader{}
        &-loader{
            background-color: transparent;
            overflow: visible;
            width: 100vw;
            height: 100vh;
            max-height: 100vh;
            padding: 0;
            &>:first-child{
                display: none;
            }
        }
    }

    //.modal-provider__action-container{}
    &__action-container {
        flex: 0 0;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin-top: 10px;
        margin-bottom: 20px;
        min-width: 450px;
        @include mobile-mlg {
            min-width: 320px;
            flex-direction: column;
            align-items: center;
            width: calc(100%);
        }
    }
}

.modal {

    //.modal-quick-view-product{}
    &-quick-view-product {
        height: 100%;
        &>div.modal-provider__content {
            height: 90%;
        }
    }

    //.modal-feedback{}
    &-feedback {
        padding: 0;
        height: 100%;
        overflow: auto;
        max-width: 550px;
    }

    //.modal-file-views{}
    &-file-views {
        padding: 0;
        height: 100%;
        width: 80vw;
        max-width: 920px;
        position: relative;
        display: flex;
        flex-flow: column nowrap;

        &>div.modal-provider__content {
            > div {
                height: 100%;
            }
        }
        @include mobile {
            width: 100%;
        }
    }
    //.modal-policy{}
    &-policy{
        padding: 0;
        height: 100%;
        width: 80vw;
        &>div.modal-provider__content {
            height: 100%;
        }
        @include mobile {
            width: 100%;
        }
    }

    //.modal-specification{}
    &-specification{
        padding: 0;
        height: 100%;
        overflow: auto;
        @include scrollbar;
        @include tablet-lgl {
            min-width: 80%;
        }
    }

    //.modal-add-to-cart{}
    &-add-to-cart {
        // height: 100%;
        padding: 0;
        &:nth-child(3) {
            padding: 0;
        }
    }

    //.modal-choose-number-order{}
    &-choose-number-order {
        padding: 5px;
        overflow: inherit;

        &>div.modal-provider__content {
            overflow: initial !important;
        }
    }

    //.modal-payment{}
    &-payment {
        padding: 0;
        overflow: auto;
        @include scrollbar;
        // height: 100%;
        max-width: 550px;
        min-width: 35%;
        &>div.modal-provider__content{
            width: calc(100% - 3px);
        }
    }
    //.modal-default{}
    &-default{
        padding: 0;
        @include mobile-min {
            min-width: 500px;
        }
    }
    //.modal-collections{}
    &-collections{
        padding: 0;
        @include scrollbar;
        @include mobile-min {
            min-width: 500px;
            width: 68vw;
            height: 100%;
            overflow: auto;
        }
    }
    //.modal-preview-photo{}
    &-preview-photo{
        @include mobile-min {
            min-width: 500px;
            height: 100%;
            overflow: auto;
            @include scrollbar;
        }
    }
    //.modal-preview-table{}
    &-preview-table{
        @include mobile-min {
            min-width: 500px;
            height: 100%;
            overflow: auto;
            @include scrollbar;
            width: 80vw;
        }
        a{
            color: $a2;
        }
    }
    //.modal-add-address{}
    &-add-address{
        padding: 0;
        width: 75%;
        height: 80vh;
        overflow: auto;
        @include mobile{
            width: 100vw;
        }
    }
    //.modal-get-my-cash{}
    &-get-my-cash{
        height: 100%;
        overflow: auto;
        @include scrollbar;
        @include mobile-min {
            min-width: 500px;
        }
        &>div.modal-provider__content>form.form {
            height: 60vh;
        }
    }
    //.modal-alert-error{}
    &-alert-error{
        // height: 100%;
        padding: 0;
        padding: 0;
        // height: 100%;
        overflow: auto;
        // max-width: 550px;
    }
    //.modal-alert-success-review{}
    &-alert-success-review{
        padding: 0;
        padding: 0;
        overflow: auto;
        &>div{
            &>div{
                height: max-content;
            }
        }
    }
}

@keyframes move-cart {
    0% {
        // left: -120px;
        left: -90%;
    }

    100% {
        // left: 450px;
        left: 90%;
    }
}

@keyframes sTransition {
    0% {
        background-position: 0%;
        // transform: scale(1);
    }
    100% {
        background-position: 400%;
        // transform: scale(1.5);
    }
}
