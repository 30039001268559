.btn {
  width: 100%;
  height: 0px;
  outline: none;
  font-weight: normal;
  font-size: 12px;
  padding-left: 0;
  color: var(--gx-color-red);
  border: 2px solid transparent;
  background: transparent;
  text-align: left;
  letter-spacing: 0.04em;
  display: block;
  padding: 0 21px 10px;
  margin-top: 8px;
  &:focus {
    border: none;
    box-shadow: none;
  }
  &:hover {

    // opacity: 0.9;
    // transform: scale(1.05);
    font-size: 14px;
  }
  &:active {
    outline: none;
  }
}
