@import '../../../styles/variables.scss';

.button--disabled{
    // background-color: $dg2 !important;
    // color: $w !important;
    // min-height: inherit;   
    opacity: .5;
    pointer-events: none;
    &:hover{
      cursor: not-allowed;
      box-shadow: none;
      // background-color: $dg2;
    }
  }
  