@import '../../styles/variables.scss';
// top: 108px;

.main-promotion {
  //.main-promotion-wrapper{}
  &-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 120px;
    left: 0;
    min-height: 66px;
    height: fit-content;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    background-image: url(../../images/PromotionAndDiscount/young.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 899;
    @include mobile {
      top: 60px;
    }
//.main-promotion-wrapper__bg{}
    &__bg {
      position: absolute;
      width: 100%;
      top: 0px;
      height: 100%;
      // opacity: 0.7;
      background: var(--gx-color-accent);
      mix-blend-mode: darken;
    }
  }
//.main-promotion__list{}
  &__list {
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 0;
    @include flex(cc);
    min-height: 44px;
    // main-promotion__list-item{}
    &-item {
      font-size: 14px;
      color: var(--gx-color-white);
      line-height: 140%;
      letter-spacing: 0.04em;
      list-style: none;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 5px 10px;
      margin: 0 25px;
      &::after {
        content: '|';
        display: inline-block;
        padding: 0 12px;
      }
      &:last-child {
        &::after {
          content: '';
          padding: 0;
        }
      }
      a {
        color: var(--gx-color-white);
        &:hover,
        &:active,
        &:focus {
          color: var(--gx-color-white);
        }
      }
    }
  }
//.main-promotion__close{}
  &__close {
    position: absolute;
    transform: translateY(-50%);
    background: none;
    border: none;
    outline: none;
    opacity: 0.5;
    z-index: 20;
    transition: 0.3s;
    box-shadow: none;
    right: 10px;
    top: 15px;    
    color: var(--gx-color-white);
    line-height: 1;
    height: auto;
    filter: invert(1);
    // todo: убрать box-shadow у кнопки
    &:active,
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
      opacity: 0.7;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
