@import '../../../styles/variables.scss';

.auth-regist{
    //.registration__container
    &__container{
        display: inline-grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        height: 100%;
        @include tablet-mmd{
            grid-template-columns: 1fr;
            grid-template-rows: auto;
        }
    }
    //.registration__block{}
    &__block{
        position: relative;
        width: 100%;
        height: 100%;
    }
}