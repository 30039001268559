@import '../../../styles/fonts/index.scss';
@import '../../../styles/variables.scss';

.cabinet {
  &-sidebar {
    //.cabinet-sidebar__store{}
    &__store {
      background: #f7f4fc;
      margin-bottom: 30px;
      border-radius: 2px;
      @include tablet-md {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
        padding-right: 10px;
      }
      @include mobile-lg {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    //.cabinet-sidebar__top{}
    &__top {
      @include flex(lc);
      flex-wrap: wrap;
      padding: 30px 30px 20px;
      @include tablet-md {
        padding: 0 5px 0 30px;
        width: 50%;
      }
      @include mobile-lg {
        padding: 0 5px;
      }
    }
    &__ava {
      //.cabinet-sidebar__ava-wrap{}
      &-wrap {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        background: $w;
        border: 2px solid $pi2;
        flex-shrink: 0;
        margin-right: 20px;
        @include flex(cc);
        overflow: hidden;

        .cabinet-sidebarstore & {
          border-color: $dg2;
        }
      }
      //.cabinet-sidebar__ava-name{}
      &-name {
        @extend %font-head;
        text-transform: capitalize;
        @include h(3);
      }
      //.cabinet-sidebar__ava-img{}
      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__user {
      //.cabinet-sidebar__user{}
      background: #fff2f3;
      margin-bottom: 30px;
      border-radius: 2px;
      @include tablet-mmd {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
        padding-right: 10px;
      }
      @include mobile-lg {
        flex-direction: column;
        align-items: flex-start;
      }
      //.cabinet-sidebar__user-info{}
      &-info {
        width: max-content;
      }
      //.cabinet-sidebar__user-name{}
      &-name {
        @extend %cab-h2;
        margin-bottom: 3px;
      }
      //.cabinet-sidebar__user-role{}
      &-role {
        @extend %text-small;
        opacity: 0.5;
      }
    }

    &__balance {
      //.cabinet-sidebar__balance-row{}
      &-row {
        @include flex(sc);
        align-items: flex-end;
        padding: 0 30px 20px;
        @include tablet-lg {
          flex-wrap: wrap;
        }
        @include tablet-md {
          padding: 0 5px;
          width: 50%;
        }
        @include mobile-lg {
          margin-top: 15px;
        }
      }
      //.cabinet-sidebar__balance-block{}
      &-block {
        padding: 0;
      }
      //.cabinet-sidebar__balance-label{}
      &-label {
        @extend %text-small;
        opacity: 0.5;
      }
      //.cabinet-sidebar__balance-value{}
      &-value {
        @extend %cab-h1;
        color: $a2;
      }
      //.cabinet-sidebar__balance-btns{}
      &-btns {
        flex-shrink: 0;
        @include tablet-lg {
          margin-top: 15px;
        }
      }
    }
    //.cabinet-sidebar__menu{}
    &__menu {
      padding-bottom: 30px;
      @include tablet-mmd {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        padding-bottom: 0;
        margin-top: 15px;
      }
      @include mobile-lg {
        flex-direction: column;
      }
      //.cabinet-sidebar__menu-link{}
      &-link {
        padding: 9px 30px;
        display: flex;
        letter-spacing: 0.5px;
        color: $p;
        font-size: 16px;
        position: relative;
        width: 100%;
        word-wrap: initial;
        justify-content: space-between;
        &>div{
          animation-name: recieveNotification;
          animation-direction: alternate;
          animation-duration: 1.5s;
          animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          animation-iteration-count: infinite;
          // opacity: 0;
        }

        @include tablet-mmd {
          width: 33%;
        }

        @include mobile {
          padding: 9px 5px;
        }

        @include mobile-lg {
          width: 100%;
        }

        &.active {
          /* background: $pil; */

          .cabinet-sidebarstore & {
            background: #eae4f5;
          }
        }

        &:hover {
          color: var(--gx-color-primary);
          background: #ffeaee;

          .cabinet-sidebarstore & {
            background: #f2eef9;
          }
        }
      }

      &-count {
        @include flex(cc);
        position: absolute;
        right: 30px;
        top: 8px;
        border-radius: 12px;
        background: $pil;
        min-width: 24px;
        height: 24px;
        padding-left: 4px;
        padding-right: 4px;
        color: $p;
        font-size: 14px;
        font-weight: bold;

        .cabinet-sidebarstore & {
          background: #eae4f5;
        }
      }
    }
    //.cabinet-sidebar__new-store-btn{}
    &__new-store-btn {
      @extend %text-btn;
      @include flex(cc);
      background: $p;
      color: $w;
      margin-bottom: 30px;
      padding: 19px 30px;
      border-radius: 2px;
      height: 70px;
      @include tablet-md {
        width: max-content;
        margin-left: 15px;
      }
      cursor: not-allowed;
      opacity: 0.5;
      text-decoration: none;
      &:hover {
        background-color: var(--gx-color-primary2);
        color: $w;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}

.mobile {
  display: none;

  @include mobile-lg {
    display: flex;
    margin: 0 auto;
  }
}

.desktop {
  @include mobile-lg {
    display: none;
  }
}

@keyframes recieveNotification {
  to {
    filter: invert(0%);
    opacity: 5%;
  }
  from {
    filter: invert(20%);
    opacity: 100%;
  }
}