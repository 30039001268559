@import '../../../styles/variables.scss';
@import '../../../styles/fonts/index.scss';

.toolTip {

    //.toolTip__wrapper{}
    &__wrapper {
        position: relative;

        //.toolTip__wrapper-trigger{}
        &-trigger {
            background-color: transparent;
            position: relative;
            top: 0px;
            left: 0px;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            pointer-events: none;
            &:hover {
                cursor: pointer;
                .tooltip {
                    visibility: visible;
                    opacity: 1;
                }
            }
            //.toolTip__wrapper-trigger--disable{}
            &--disable{
                display: none;
            }
        }
    }

    //.toolTip__container{}
    &__container {
        position: relative;
        &:hover {
            .toolTip__tooltip{
                // position: relative;
                opacity: 1;
                visibility: visible;
            }

        }
    }

    //.toolTip__tooltip{}
    &__tooltip {
        display: flex;
        position: absolute;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 30px;
        height: auto;
        background-color: var(--gx-color-white);
        font-size: 12px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.11);
        border: 2px solid var(--gx-color-gray);
        padding: 10px 7px;
        min-width: 160px;
        font-size: 12px;
        @extend %font;
        visibility: hidden;
        opacity: 0;
        transition: .6s all cubic-bezier(.9, .03, .69, .22);
        @include mobile {
            transform: translate(-24px, -66px);
        }
    }
    
}

.top-center{
    left: 50%;
    transform: translate(0%, -42px);
}