.pdfviewer {

    //.pdfviewer__container-navigation
    &__container-navigation {
        padding: 0;
        display: flex;
        justify-content: center;
    }

    //.pdfviewer__pdf-viewer-block{}
    &__pdf-viewer-block {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        left: 0;
        top: 0;

        &>div {
            width: 100%;

            &>canvas {
                width: 100% !important;
                height: auto !important;
                margin-bottom: 50px;
            }

            &>div {
                width: 0;
                display: none;
            }
        }
    }
    //.pdfviewer__navigation-btn--prev{}
    &__navigation-btn--prev{
        padding: 0;
    }
    //.pdfviewer__navigation-btn--prev--right{}
    &__navigation-btn--prev--right{
        padding: 0;
    }
    //.pdfviewer__navigation-btn--prev--left{}
    &__navigation-btn--prev--left{
        padding: 0;
    }
    //.pdfviewer__navigation-btn--next{}
    &__navigation-btn--next{
        padding: 0;
    }
    //.pdfviewer__navigation-number{}
    &__navigation-number{
        padding: 5px;
    }
}