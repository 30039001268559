@import '../../../../styles/variables.scss';

.top-header {
  //.top-header-menu{}
  &-menu {
    height: 100%;
    color: $p;
    display: flex;
    align-items: center;
    // @include tablet-md {
    //   display: none;
    //   position: fixed;
    //   width: 100%;
    //   height: 100%;
    //   z-index: 9999;
    //   top: 58px;
    //   left: 0px;
    // }
    //.top-header-menu__list{}
    &__list {
      @include ul;
      display: flex;
      @include tablet-md {
        display: none;
        // padding: 40px;
        // margin: 0;
        // height: calc(100% - 59px);
        // list-style: none;
        // display: flex;
        // background-color: $p;
        // flex-direction: column;
        // flex-wrap: wrap;
        // align-content: flex-start;
        // justify-content: flex-start;
      }
      // @include mobile {
      //   position: absolute;
      //   background: $w;
      //   top: -5px;
      //   width: max-content;
      //   right: 0;
      //   overflow: auto;
      //   flex-direction: column;
      //   width: 100%;
      //   height: calc(100vh - 45px);
      //   display: flex;
      //   align-items: stretch;
      //   align-content: flex-start;
      //   justify-content: flex-start;
      //   flex-wrap: wrap;
      // }
    }
    //.top-header-menu__li{}
    &__li {
      // отключаем пункт живые фото
      &:nth-child(2){
        display: none;
      }
      position: relative;
      margin: 5px 20px;
      cursor: pointer;
      letter-spacing: 0.06px;
      &>a{
        color: $w;
        position: relative;
        padding: 10px;
        letter-spacing: .8px;
        font-size: 18px;
        &::before{
          transition: transform .6s ease-out;
          position: absolute;
          right: 0;
          font-size: 8px;
          transform: translate(9px, 6px) rotate(180deg);
        }
        &:hover{
          color: $pi;
          &::before{
            transform: translate(9px, 6px) rotate(0deg);
          }
        }
      }
      &:hover{
        color: $pi;
        .top-header-submenu{
          transform: translateY(100%);
          opacity: 1;
          visibility: visible;
        }
      }
      @include tablet-md {
        background-color: $p;
        &>a{
          font-size: $fontSize;
          color: $back;
          position: relative;
          padding: 10px;
          &::before{
            transition: transform .6s ease-out;
            position: absolute;
            right: 0;
            font-size: 8px;
            transform: translate(9px, -12px) rotate(180deg);
          }
          &:hover{
            color: $pi;
            &::before{
              transform: translate(9px, -12px) rotate(0deg);
            }
          }
        }
      }
      @include mobile {
        padding: 10px 30px;
        background-color: $w;
      }
    }
    &__li-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $w;
      font-size: $fontSize;
      &:hover {
        color: $pi;
        @include mobile {
          color: $p;
        }
      }
      transition: 0.3s;
      @include mobile {
        color: $p;
      }
    }
  }

  //.top-header-submenu{}
  &-submenu {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 30px;
    border-top: 20px;
    font-size: 16px;
    white-space: nowrap;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    background-color: $w;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: transform .9s cubic-bezier(.68,-0.55,.27,1.55);
    box-shadow: 0px 18px 18px rgba(0, 0, 0, 0.2);
    &__list {
      display: grid;
      grid-gap: 20px 0;
      li > a {
        color: $p;
        font-size: 1rem;
        &:hover {
          color: var(--gx-color-accent2);
        }
        @include tablet-md {
          color: $w;
        }
        @include mobile {
          color: $p;
        }
      }
      @include mobile {
        grid-gap: 10px 0;
      }
    }
    @include tablet-md {
      background-color: $p;
      box-shadow: none;
    }
    @include mobile {
      background-color: $w;
      padding-left: 33px;
      padding-right: 0;
      padding-top: 0px;
      padding-bottom: 20px;
      border: none;
      box-shadow: none;
      text-transform: unset;
    }
  }
}

.hidden {
  display: none;
}
.visible {
  display: block;
}
.item-modificator {

  color: $w;
  &:hover {
    color: var(--gx-color-accent1);
  }
  @include mobile {
    color: $p;
  }
}
.item-list{
  color: black;
  &:hover {
    color: var(--gx-color-accent1);
  }
  @include mobile {
    color: $p;
  }
}