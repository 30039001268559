@import './fonts/index.scss';

:root {
  --gx-color-white: #fff;
  --gx-color-blue: #1e34ff;
  --gx-color-lightmauve: #ec8c97;
  --gx-color-primary: #282534;
  --gx-color-black: #1d1b1e;
  --gx-color-primary2: #4b454b;
  --gx-color-accent: #583f4c;
  --gx-color-accent2: #933468;
  --gx-color-accent2-rgb: 147, 52, 104;
  --gx-color-pink: #ffa6b0;
  --gx-color-pink2: #f2aab3;
  --gx-color-pink3: #fee9eb;
  --gx-color-lightpink: #fcd2d7;
  --gx-color-lightpink-rgb: 252, 210, 215;
  --gx-color-darkgray: #dbd7dc;
  --gx-color-darkgray2: #a5a0a7;
  --gx-color-pre-white: #fbfafb;
  --gx-color-gray: #f3eff3;
  --gx-color-gray2: #f7f4fc;
  --gx-color-red: #e292c8;
  --gx-color-green: #7abb7d;
  --gx-color-palePurple: #faf5f7;
  --gx-input-border-color-focus: var(--gx-color-pink3);
  --gx-focus-ring-box-shadow: transparent;
  --gx-input-color: #282534;
  --gx-input-dark: #3d3b47;
  --icon-width: 24px;
  --icon-height: 24px;
  --gx-color-primary-hue: 0;
  --gx-z-index-dialog: 1000;
  --gx-input-font-family: %font;
  --gx-font-sans: %font;
  --gx-overlay-background-color: #583f4c;
  --textLink: #820c78;
  --line: #BBC1E1;
  --line-active: #275EFE;
  --gx-font-weight: 500;
  --gx-transition-fast: .6s;
  --gx-input-height-medium: auto;
  --height-block-spinner: 100px;

  --gx-color-primary-saturation: 100%;
    --gx-color-primary-text: var(--gx-color-white);
    --gx-color-success-hue: 110;
    --gx-color-success-saturation: 70%;
    --gx-color-success-text: var(--gx-color-white);
    --gx-color-secondary-hue: var(--gx-color-primary-hue);
    --gx-color-secondary-saturation: 10%;
    --gx-color-secondary-text: var(--gx-color-white);
    --gx-color-warning-hue: 40;
    --gx-color-warning-saturation: 90%;
    --gx-color-warning-text: var(--gx-color-white);
    --gx-color-danger-hue: 10;
    --gx-color-danger-saturation: 95%;
    --gx-color-danger-text: var(--gx-color-white);
    --gx-color-gray-hue: var(--gx-color-primary-hue);
    --gx-color-gray-saturation: 10%;
    --gx-color-gray-text: var(--gx-color-white);
    --gx-color-primary-5: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),5%);
    --gx-color-primary-10: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),10%);
    --gx-color-primary-15: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),15%);
    --gx-color-primary-20: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),20%);
    --gx-color-primary-25: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),25%);
    --gx-color-primary-30: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),30%);
    --gx-color-primary-35: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),35%);
    --gx-color-primary-40: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),40%);
    --gx-color-primary-45: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),45%);
    --gx-color-primary-50: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),50%);
    --gx-color-primary-55: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),55%);
    --gx-color-primary-60: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),60%);
    --gx-color-primary-65: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),65%);
    --gx-color-primary-70: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),70%);
    --gx-color-primary-75: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),75%);
    --gx-color-primary-80: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),80%);
    --gx-color-primary-85: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),85%);
    --gx-color-primary-90: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),90%);
    --gx-color-primary-95: hsl(var(--gx-color-primary-hue),var(--gx-color-primary-saturation),95%);
    --gx-color-success-5: hsl(var(--gx-color-success-hue),var(--gx-color-success-saturation),5%);
    --gx-color-success-10: hsl(var(--gx-color-success-hue),var(--gx-color-success-saturation),10%);
    --gx-color-success-15: hsl(var(--gx-color-success-hue),var(--gx-color-success-saturation),15%);
    --gx-color-success-20: hsl(var(--gx-color-success-hue),var(--gx-color-success-saturation),20%);
    --gx-color-success-25: hsl(var(--gx-color-success-hue),var(--gx-color-success-saturation),25%);
    --gx-color-success-30: hsl(var(--gx-color-success-hue),var(--gx-color-success-saturation),30%);
    --gx-color-success-35: hsl(var(--gx-color-success-hue),var(--gx-color-success-saturation),35%);
    --gx-color-success-40: hsl(var(--gx-color-success-hue),var(--gx-color-success-saturation),40%);
    --gx-color-success-45: hsl(var(--gx-color-success-hue),var(--gx-color-success-saturation),45%);
    --gx-color-success-50: hsl(var(--gx-color-success-hue),var(--gx-color-success-saturation),50%);
    --gx-color-success-55: hsl(var(--gx-color-success-hue),var(--gx-color-success-saturation),55%);
    --gx-color-success-60: hsl(var(--gx-color-success-hue),var(--gx-color-success-saturation),60%);
}


h1,
h2,
h3,
h4,
h5,
h6 {
  // @extend %font-head;
}

body, html {
  @extend %font;
  background-color: #fff;
  color: var(--gx-color-primary);
  line-height: 1.4;
  font-weight: 400;
  font-size: 14px;
  padding: 0;
  margin: 0;
  position: relative;
}
html, body, img, div, a, table {
  image-rendering: -webkit-optimize-contrast !important;
  }


.video-react-video {
  width: 100%;
  position: relative;
  z-index: -1;
}

img {
  display: initial;
}

.video-react-control-bar,
.video-react-button,
.video-react-loading-spinner {
  display: none;
}



.productdescription__inner-title {
  margin: 0 15px;
}

.select__label {
  color: #000;
}

//.error{}
.error{
  padding: 0;
}

.button--full {
  width: 100%;
}

.button--med {
  font-size: var(--gx-button-font-size-medium);
  height: var(--gx-input-height-medium);
  line-height: var(--gx-input-line-height-medium);
  border-radius: var(--gx-input-border-radius-medium);
  padding: 0 var(--gx-spacing-large);
}

.button {
  display: inline-flex;
  align-items: stretch;
  justify-content: center;
  align-items: center;
  padding: 5px;
  // border: 1px solid;
  @extend %font;
  font-weight: var(--gx-font-weight);
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  vertical-align: middle;
  transition: var(--gx-transition-fast) background-color, var(--gx-transition-fast) color, var(--gx-transition-fast) border, var(--gx-transition-fast) box-shadow;
  cursor: inherit;

  &:hover {
    cursor: pointer;
  }
}

.link{  
  &:hover {
    cursor: pointer;
    filter: invert(.5);
  }
}

._flyImage{
  transform: scale(7.2);
  position: fixed;
  opacity: 1;
  z-index: 100;
  transition: all 3s ease 0s;
}

.hidden{
  opacity: 0;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: -120%;
  top: 0px;
  pointer-events: all;

  &:hover{
    cursor: pointer;
  }
}

div#pdfviewer> div > div.viewer-layout-toolbar > div > div.viewer-toolbar-left,
div#pdfviewer> div > div.viewer-layout-toolbar > div > div.viewer-toolbar-right {
  display: none;
  padding: 0;
}

div#pdfviewer> div > div.viewer-layout-main {
  overflow: auto;
}

iv#pdfviewer-specif> div > div.viewer-layout-toolbar > div > div.viewer-toolbar-left,
div#pdfviewer-specif> div > div.viewer-layout-toolbar > div > div.viewer-toolbar-right {
 padding: 0;
}

div#pdfviewer-specif> div > div.viewer-layout-main {
  // overflow: auto;
  padding: 0;
}


.requisites{
  display: block;
  @media screen and (max-width: 768px) {
    text-align: left;
    padding-left: 10px;
  }
  & > p {
    pointer-events: none;
  }
}

.inner-areon{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  animation: pulse-mobile 1.5s infinite;
  transition: background-color .3s, color .3s, box-shadow .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;
}

.react-date-picker__wrapper {
  border: none;
}
.react-date-picker__calendar {
  z-index: 99999;
  position: absolute;
  inset: 100% auto auto 0px;
}
.react-date-picker__clear-button {
  display: none;
}

.ct-toast{
  top: 53px;
  position: relative;
}
.productdescription__row{
  p{
    line-height: 25px;
    font-size: 14px;
    letter-spacing: 1px;
  }
}

.inner-chat{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  animation: pulse-mobile 1.5s infinite;
  transition: background-color .3s, color .3s, box-shadow .3s;
  position: fixed;
  z-index: 400;
  bottom: 50px;
  right: 50px;
  display: flex;
  text-align: center;
  justify-content: center;
  > div {
    top: 17px;
    left: 10px;
    position: relative;
  }
  @include mobile {
    bottom: 80px;
    right: 17px;
  }
  &:hover{
    animation: none;
    cursor: pointer;
  }
}

.inner-chat-teleg{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  animation: pulse-mobile 1.5s infinite;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  position: fixed;
  z-index: 400;
  bottom: 128px;
  right: 53px;
  display: flex;
  text-align: center;
  justify-content: center;

  @include mobile {
    bottom: 141px;
    right: 17px;
  }
  &:hover{
    animation: none;
    cursor: pointer;
  }
}

.sendpulse-send-to-tg.xlarge.blue{
  width: 162px;
  right: -53px;
  position: fixed !important;
  transform: scale(0.5) translate(136px, -55px);
  transition: all .8s ease-out;
  &:hover{
    transform: scale(0.8) translate(-20px, -50px);
  }
  @include tablet-md{
    transform: scale(0.5) translate(104px, -66px);
    &:hover{
      transform: scale(0.5) translate(104px, -66px);
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid green;
  -webkit-text-fill-color: rgb(0, 0, 0);
  -webkit-box-shadow: 0 0 0px 1000px #fff0 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.information-delivery__wrapper-important-exchange{
  padding: 15px 1px;
  font-weight: 200;
}
.react-date-picker__calendar.react-date-picker__calendar--open{
  z-index: 999;
}
.modal-message{
  padding: 15px;
  font-size: 21px;
  text-align: center;
  letter-spacing: 1.1px;
  line-height: 160%;
}

.dataintro-step1{
  width: 200px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.514);
  border: 2px dotted #820c78;
}

.dataintro-step2{
  background-color: rgba(146, 134, 133, 0.4);
  border: 2px dotted #820c78;
}

.dataintro-step3{
  background-color: rgba(51, 50, 50, 0.24);
  border: 2px dotted #4bb95e;
}
.dataintro-step4{
  background-color: rgba(51, 50, 50, 0.24);
  border: 2px dotted #4bb95e;
}
.dataintro-step5{
  background-color: rgba(51, 50, 50, 0.24);
  border: 2px dotted #4bb95e;
}

.marquee-btn{
  font-size: 18px;
  color: rgba(245, 138, 124, 0.678);
}


.introjs-tooltipbuttons,
  .introjs-tooltiptext,
  .introjs-bullets,
  .introjs-tooltip-header{
    background-color: #fee9eb !important;
    color:rgb(0, 0, 0) !important;
    &>a,h1,div{
      color:rgb(0, 0, 0) !important;
    }
  }
  .introjs-button.introjs-nextbutton{
    color:#000000 !important;
  }
  .introjs-nextbutton:hover{
    color:#820c78 !important;
    border: 1px solid #820c78 !important;
  }
  .introjs-prevbutton{
    color:rgb(0, 0, 0) !important;
  }
  .introjs-prevbutton:hover{
    color:#820c78 !important;
    border: 1px solid #820c78 !important;
  }

  .swiper-button-prev,
  .swiper-button-next{
    @include mobile{
      display: none !important;
    }
  }

.pdf-views-global{
  width: 100%;
  height: 100%;
}
.errorpage{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.errorpage__title{
  align-items: center;
  color:rgba(245, 138, 124, 0.678);
  font-size: 54px;
}
.errorpage__heading{
  font-size: 20px;
}
.errorpage__btns{
  border: 1px solid $dg2;
  border-radius: 5px;
  margin-top: 25px;
  color: #000;
  padding: 10px 30px;
}
@keyframes pulse-mobile{
  0%{
    box-shadow: 0 0 0 0 transparentize(#e29a9a, .6)}
  70%{
    box-shadow: 0 0 0 25px transparentize(#e29a9a, 1)}
  100%{
    box-shadow: 0 0 0 0 transparentize(#e29a9a, 1)}
}
