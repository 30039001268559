@import '../../styles/variables.scss';
@import '../../styles/fonts/index.scss';

.footer {
  &-info {
    font-size: 18px;
    line-height: 1.6;
    color: #8f8c98;
    margin-right: 37px;

    @include tablet-lg {
      margin: 0;
      width: 100%;
    }

    &.right {
      max-width: 410px;
      width: 100%;
      margin-right: 0;

      @include tablet-lg {
        max-width: 100%;
      }
    }
//.footer-info__title{}
    &__title {
      // @include h(4);
      @extend %heading;
      font-size: 24px;
      margin-bottom: 5px;
      text-align: center;
      margin-top: 7px;
    }
//.footer-info__content{}
    &__content {
      margin-top: 10px;
      font-size: 12px;
      &>div{
        &>p{
          &>a{
            color: $a2;
          }
        }
      }
      &>div>:first-child {
        display: none;
      }

      &>div>:last-child {
        display: none;
      }

      @include mobile {
        text-align: center;
      }

      p {
        margin: 0;
      }
    }
//.footer-info__link{}
    &__link {
      @include flex(lc);
      margin-top: 5px;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
      letter-spacing: 0.02em;
      @media (max-width:480px) {
        justify-content: center;
      }
      //.footer-info__link--disable{}
      &--disable{
        cursor: not-allowed;        
      }
//.footer-info__link-name{}
      &-name {
        color: var(--gx-color-accent2);
        border-bottom: 1px dashed var(--gx-color-accent2);
        font-weight: 400;
      }
//.footer-info__link-img{}
      &-img {
        height: 20px;
        width: auto;
        margin-right: 6px;
        &>img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  //.footer-logo
  &-logo {
    // right: -40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    width: 270px;
    margin-right: 30px;

    &>a {
      // height: 140px;
    }

    @media (max-width: 680px) {
      right: 0px;
    }

    @media (max-width: 540px) {
      margin-right: 0px;
    }


  }
}



.relative {
  &-container {
    @include tablet-lg {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }

    @include mobile {
      flex-direction: column;
      align-items: center;
    }
  }
}