@import '../../../styles/fonts/index.scss';
@import '../../../styles/variables.scss';



.cabinet-menu{
  //.cabinet-menu__wrapper{}
  &__wrapper{
    position: relative;
    max-width: 276px;
    width: 100%;
  }
  //.cabinet-menu__menu-list{}
  &__menu-list{
    width: 100%;
    padding-bottom: 5px;
  }
  //.cabinet-menu__item{}
  &__item{
    padding: 10px 0;
    position: relative;
    display: flex;
    align-items: stretch;
    font-family: var(--gx-font-sans);
    font-size: var(--gx-font-size-medium);
    font-weight: var(--gx-font-weight-normal);
    line-height: var(--gx-line-height-normal);
    letter-spacing: var(--gx-letter-spacing-normal);
    text-align: left;
    color: var(--color-gray-40);
    padding: 6px;
    transition: var(--gx-transition-fast) fill;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
    &:hover{
      background-color: var(--gx-color-primary-95);
    }
    // &:not(:last-child){
    //   margin-bottom: 5px;
    // }
    &>a:hover{
      color: $a2;
    }
  }
  //.cabinet-menu__link{}
  &__link{
    @extend %font;
    color: $p;
    font-size: $fontSize;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 15px;
    &>div{
      animation-name: recieveNotification;
      animation-direction: alternate;
      animation-duration: 1.5s;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      animation-iteration-count: infinite;
      // opacity: 0;
    }
    &:hover{
      color: $a;
    }
  }
  //.cabinet-menu__bage{}
  &__bage{
    @extend %font;
    font-style: normal;
    color: $p;
    font-weight: bold;
  }
}

@keyframes recieveNotification {
  to {
    filter: invert(0%);
    opacity: 5%;
  }
  from {
    filter: invert(20%);
    opacity: 100%;
  }
}