@import '../../../styles/variables.scss';
@import '../../../styles/fonts/index.scss';

//.news-details-page__slider_item{}
.news-details-page{
  &__slider_item{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 0 !important;  
    &>video.video-react-video{
      position: absolute;
      top: 0;
    }
  }
}
.wrapper {
  // min-height: calc(100vh - 105px);
  overflow: hidden;
  // background: $a2;
  background-color: #75415e45;
  mix-blend-mode: multiply;
  position: relative;
  width: 100%;
  // height: 100%;
  &__image {
    background: $a2;
    mix-blend-mode: multiply;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &__line {
    height: 1px;
    opacity: 0.2;
    width: 60%;
    background: $w;
  }
  &__marcket-info {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
  }
  &__upper-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding-left: 30%;
    padding-right: 30%;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $pil;
    @include mobile-md {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
  &__midle-title {
    // font-family: Cormorant Garamond;
    @extend %font-title;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    font-size: 56px;
    padding-left: 15%;
    padding-right: 15%;
    line-height: 130%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $w;
    @include mobile-md {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__lower-title {
    // font-family: Cormorant Garamond;
    @extend %font-title;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    padding-left: 30%;
    padding-right: 30%;
    line-height: 130%;
    text-align: center;
    margin-top: 25px;
    color: $w;
    @include mobile-md {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
}
