@import '../../../styles/variables.scss';
@import '../../../styles/fonts/index.scss';

//.input__wrapper{}
.input{
    &__wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border: 1px solid $dg2;
        border-radius: 5px;
        position: relative;
        margin-bottom: 15px;
        min-width: 325px;
        @include mobile {
            min-width: 280px;
        }
    }
    //.input__input{}
    &__input{
        font-size: $fontSize - 2px;
        width: 100%;
        letter-spacing: 1.1px;
        line-height: 140%;
        &::placeholder{
            color: $dg2;
            font-size: 14px;
            letter-spacing: 1.1px;
            line-height: 140%;
        }
    }
    //.input__label{}
    &__label{
        font-size: calc($fontSize - 4px);
        color: $dg2;
        @extend %font;
        margin: 0 0 0 10px;
    }
    //.input__label-bottom{}
    &__label-bottom{
        font-size: calc($fontSize - 4px);
        color: #b6b6b6;
        margin: 0 0 0 -4px;
        position: absolute;
        bottom: -3px;
    }
    //.input__error{}
    &__error{
        position: relative;
        &>input{
            color: $rd;
        }
    }
}

//.pagination-input-field{} 
.pagination-input-field{
    min-width: 58px;
    max-width: 58px;
    max-height: 35px;
    margin: 0 5px 0 0;
    & > .input__input {
        text-align: center;
    }
}

[name=input-search]{
    width: 320px;
    background: transparent;
    margin: 5px;
    padding: 0 12px 0 8px;
    @include tablet-lg{
        width: 100%;
    }
    &::-webkit-input-placeholder {
        /* Edge */
        color: $dg2;
        font-size: $fontSize;

    }
    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $dg2;
        font-size: $fontSize;
    }
    &::placeholder {
        color: $dg2;
        font-size: $fontSize;
    }
}

.input-mt_20{
    margin-bottom: 20px;
}

.cabinet-orders-details{
    //.cabinet-orders-details__comment-input{}
    &__comment-input{
        width: 100%;
        // background-color: rgba(243, 239, 243, 0.3);
        border: none;
        border-bottom: 1px solid;
        margin-bottom: 10px;
        min-width: 0;
        &>input{
            margin-bottom: -8px;
            background-color: transparent;
        &::placeholder {
            color: $dg2;
            font-size: 10px;
        }
        }
    }
}

.cabinet-lk__search{
    padding: 0;
    position: relative;
    width: 255px;
    height: 40px;
    min-width: 255px;
    border: 1px solid $dg;
    margin-left: 5px;
    &>input{
        padding: 5px 0 0 30px;
    }
    @include tablet-md {
        margin-left: 19px;
    }
    @include mobile {
        width: 273px;
    }

}