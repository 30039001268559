@import '../../../styles/variables.scss';

.btn {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  height: 70px;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--gx-color-white);
  letter-spacing: 1px;
  background-color: $p;
  border-radius: 2px;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 26px 18px rgba(0, 0, 0, 0.2);
    background-color: $p2;
  }
  &:active {
    outline: none;
    box-shadow: 0px 26px 18px rgba(0, 0, 0, 0.2);
  }
}

.black_btn_full_width-modal{
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  height: 50px;
  max-width: 175px;
  outline: none;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
  color: var(--gx-color-white);
  letter-spacing: 1px;
  background-color: $p;
  border-radius: 2px;
  overflow: hidden;
  white-space: pre-wrap;
  line-height: 170%;
  &:not(:last-child){
    margin-right: 10px;
    margin-bottom: 10px;
  }
  @include mobile {
    &:not(:last-child){
      margin-right: 0;
    }
  }
  &:hover {
    box-shadow: 0px 26px 18px rgba(0, 0, 0, 0.2);
    background-color: $p2;
  }
  &:active {
    outline: none;
    box-shadow: 0px 26px 18px rgba(0, 0, 0, 0.2);
  }
}