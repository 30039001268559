@import '../../styles/variables.scss';

.wrapper{
    padding: 15px 0;
    border: 1px solid $dg;
    background-color: $g2;
    position: absolute;
    width: 275px;
    transform: translate(-175px,45px);
    &>a{
        margin: 0 auto;
        max-width: 250px;
    }
    &::before{
        content: '';
        // top: 0;
        width: 0px;
        height: 0px;
        // background-color: #000;
        position: absolute;
        // transform: translate(161px, -8px) rotate(314deg);
        left: 177px;
        top: -11px;
        transform: rotate(-45deg);
        border: 11px solid;
        border-color: $g2 $g2 transparent transparent;    
    }
    &::after{
        content: '';
        // top: 0;
        width: 21px;
        height: 21px;
        position: absolute;
        //transform: translate(-126px, -255px) rotate(-45deg); //translate(73px, -336px) rotate(-45deg); //translate(162px, -198px) rotate(-45deg);
        left: 177px;
        top: -11px;
        transform: rotate(-45deg);
        border: 2px solid;
        border-color: $dg $dg transparent transparent;
    }

    @include mobile {
            transform: translate(-155px);
            bottom: 55px;
            &::before{
                content: '';
                width: 0px;
                height: 0px;
                position: absolute;
                border: 11px solid;
                border-color: $g2 $g2 transparent transparent;        
                left: 50%;
                top: 100%;
                transform: translate(-50%, -10px) rotate(134deg);
            }
            &::after{
                content: '';
                width: 21px;
                height: 21px;
                position: absolute;
                left: 50%;
                top: 100%;
                transform: translate(-50%, -10px) rotate(134deg);               
                border: 2px solid;
                border-color: $dg $dg transparent transparent;

            }
        }
}