@import '../../../styles/variables.scss';

.bottom-header {
    position: fixed;
    padding: 5px 0 5px; 
    width: 100%;
    top: 48px;
    left: 0;
    z-index: 899;
    background-color: var(--gx-color-white);
    transition: 0.2s;
    box-shadow: 0px 0px 6px grey;
    @include tablet-lg {
      // top: 35px;
      transition: none;
      
    }
    @include tablet-md {
      // margin-top: 13px;
      transition: none;
    }
    @include mobile {
      top: auto;
      bottom: 0;
      max-height: 100%;
      // min-height: 100%;
      height: 60px;
      padding-top: 5px;
      padding-bottom: 0;
      z-index: 9999;
    }
    @include padding-for-mobile;
//   *********************
    //.bottom-header-wrapper{}
    &-wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0 60px;
      margin-top: 10px;
      width: 100%;
  
      @include padding-for-mobile;
      @include mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 0;
        justify-content: center;
      }
    }
    //.bottom-header__group{}
    &__group {
        display: flex;
        flex-direction: row;
        // justify-content: space-around;
        align-content: center;
        flex-wrap: nowrap;
        width: 100%;
        // max-width: 1920px;
        align-items: center;
        flex: 1 1;
        @include tablet-md{
          width: 65%;
        }
        @include mobile {
          display: none;
        }
      }
    //.bottom-header-logo{}
    &-logo {
      // margin-right: 100px;
      flex: 0 0 250px;
      display: flex;
      justify-content: center;
      // @media (max-width: 960px) {
      //   flex: 0 0 50%;
      // }   
  //  @include mobile {
  //     flex: 0 0 100%;
  //       width: auto;
  //     }
    }
    //.bottom-header__group-menu{}
    &__group-menu {
      // padding-right: 100px;
      position: relative;
      flex: 1 1;
      
      @media (min-width: 1670px) {
        font-size: calc((12 + 14) * ((100vw - 320px) / (1920 - 320)));
        flex: 1 1;
  
      }
      // font-size: 24px;
      width: 100%;
    }
// ***********************
    &-menu {
      position: relative;
      width: 100%;
      // margin-right: 550px;
  
      @include mobile {
        display: none;
      }
      
    }
    &__burger-wrapper {
      display: none;
      
      @include tablet-md {
        display: block;
        top: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        .hydrated button {
          color: pink;
        }
      }
    }
    &__burger-button {
      --hamburger-color: var(--gx-color-white);
      @include mobile {
        --hamburger-color: var(--gx-color-accent2);
      }
    }
  }
  