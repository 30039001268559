.balance{
    //.balance__now{}
    &__now{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        align-items: flex-end;
        &--red{
            &>span{
                color:rgb(97, 46, 78);
                font-weight: 900;
            }
        }
    }
    //.balance__all-price{}
    &__all-price{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        align-items: flex-end;
        &>span{
            font-size: 18px;
            color: rgb(10, 106, 34);
        }
    }
}