@import '../../../styles/variables.scss';
.form{
    width: 100%;
    background-color: $fontLayout;
    @include scrollbar;
    overflow: hidden;
}

.feedback-container{
    position: relative;
    min-width: 300px;
    padding: 0 10px;
    
}

.drop-down{
    //.drop-down__form{}
    &__form{
        margin-right: 15px;
        background-color: transparent;
        overflow: visible;
    }
}