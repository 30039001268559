@import '../../../../styles/variables.scss';

//.header-buttons{}
.header-buttons {
    flex: 0 0 200px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    column-gap: 20px;
    font-size: 25px;
    max-width: 210px;

    @include tablet-lg {
      flex: 1 1;
    }
    @include mobile {
      justify-content: space-between;
      padding-bottom: 10px;
      padding-top: 10px;
      width: 100%;
    }

    &.scrolled {
      width: 100%;
      > a {
        position: absolute;
        left: calc(50% - 114px);
        @include mobile {
          display: none;
        }
      }
    }
    //.header-buttons__links{}
    &__links{
      color: $a;
      &> span::before{
        pointer-events: none;
      }
      &:hover{
        color: $a2;
        cursor: pointer;
        font-size: 27px;
      }
    }
    //.header-buttons__count-in{}
    &__count-in{
      position: absolute;
      left: 14px;
      top: 16px;
      font-size: 12px;
      background-color: $pi2;
      width: 17px;
      height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-weight: 600;
      pointer-events: none;
    }
     //.header-buttons__lk-profile-btn{}
    &__lk-profile-btn{
      width: 30px;
      height: 30px;
      position: relative;
      cursor: pointer;
      color: $a;
      &>span{
        &:hover{
          color: $a2;
          cursor: pointer;
          font-size: 27px;
        }
      }
      &:nth-child(2){
        display: none;
        @include laptop-sm{
            display: flex;
            justify-content: center;
            align-items: center;
        }
      }        
    }

    &-icons {
      display: grid;
      grid-auto-flow: column;
      gap: 0 32px;
      // margin-left: 21px;
      position: relative;
      > a {
        color: var(--gx-color-primary);
      }
      @include mobile {
        width: 100%;
        margin: 0;
        @include padding-for-mobile;
        > * {
          text-align: center;
        }
      }
    }
  
    &__icon {
      position: relative;
      --icon-width: 24px;
      --icon-height: 24px;
        width: 25px;
        height: 25px;
      --gx-input-height-medium: auto;
      --gx-input-border-width: 0;
      --gx-spacing-large: 0;
      cursor: pointer;
      &::part(base) {
        width: 25px;
        box-shadow: none;
        line-height: 1;
        color: var(--gx-color-white);
      }

      &-catalog {
        display: none;
        @include laptop-sm {
          display: block;
        }
      }

      &.light {
        box-shadow: none;
        line-height: 1;
        color: var(--gx-color-white);
      }

      &.dark {
        &::part(base) {
          color: var(--gx-color-accent);
        }
      }

      &.hide {
        display: none;
      }
    }

    &__badge {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: -5px;
      right: -8px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: var(--gx-color-lightpink);
      color: var(--gx-color-primary);
      font-size: 14px;
      font-weight: bold;
      &.empty {
        display: none;
      }
    }

    &-lang,
    &-curr {
      padding: 8px 8px; 
      font-size: 16px;
      cursor: pointer;
      position: relative;
      z-index: 1;
      @include mobile {
        position: absolute;
        right: 5px;
      z-index: 100;
  
      }

      &.open {
        background-color: var(--gx-color-white);
        color: var(--gx-color-primary);
        .header-buttons-lang__top,
        .header-buttons-curr__top {
          color: var(--gx-color-primary);
        }
      }

      &::part(base) {
        font-size: 16px;
        font-weight: bold;
        padding: 0;
        & > div {
          inset: none;
          left: 0;
        }
      }
      &::part(panel) {
        border: none;
        transform: translate(-5px, 14px);
        box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.1);
      }
  
      &__list {
        padding: 0 5px 9px;
        border-radius: 4px;
        &::part(base) {
          padding: 0;
        }
      }
  
      &__top,
      &__list-item::part(base) {
        width: 54px;
        padding: 5px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
      }
      &__top {
        color: var(--gx-color-white);
        display: flex;
        justify-content: space-between;
        align-items: baseline;
         @include tablet-md {
          color: brown;
        }
        &.scrolled {
          color: var(--gx-color-primary);
        }
      }
      &__list-item {
        &::part(base) {
          color: var(--gx-color-primary);
          &:hover {
            background-color: var(--gx-color-gray);
          }
        }
      }
      &__arrow {
        width: 9px;
        height: 5px;
        transform: rotate(180deg);
        transition: 0;
        &.scrolled {
          color: var(--gx-color-primary);
        }
        &.open {
          transform: rotate(0deg);
        }
      }
      @include mobile {
        //делает скрытыми наименование валюты
        // display: none;
      }
    }
    &-lang {
      margin-right: 3px;
    }
  }
