@import '../../../styles/fonts/index.scss';
@import '../../../styles/variables.scss';

//.cabinet{}
.cabinet {
  //.cabinet__container{}
  &__container {
    @extend %container;
  }

  &-sidebaruser {
    max-width: 600px;
  }
  //.cabinet-row{}
  &-row {
    margin-top: 30px;
    @include row(15);
    @include laptop-sm {
      margin-left: 0;
      margin-right: 0;
    }
    @include tablet-md {
      // flex-direction: column;
      width: 100%;
    }
  }
  //.cabinet-left-col{}
  &-left-col {
    @include col(3, 15);

    @include tablet-mmd {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-right: 10px;
      margin-left: 10px
    }

    @include mobile {
      flex-direction: column;
      align-items: center;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }
  }
  //.cabinet-right-col{}
  &-right-col {
    @include col(8, 15);

    @include tablet-md {
      margin-right: 0px;
      margin-left: 0px;
      padding: 0 10px;
    }

    @include tablet-mmd {
      width: 100%;

    }
  }
  //.cabinet-delaccount{}
  &-delaccount {
    @include flex(lc);
    opacity: 0.75;
    margin-left: auto;
    &:hover {
      opacity: 1;
    }
    //.cabinet-delaccount__text{}
    &__text {
      color: $rd;
      @extend %text-link;
    }
    //.cabinet-delaccount__icon{}
    &__icon {
      width: 20px;
      display: block;
      margin-left: 5px;
    }
  }

  &-form {
    //.cabinet-form--alignright{}
    &--alignright{
      padding: 0;
      margin-top: 32px;
    }
    //.cabinet-form__link--blue{}
    &__link--blue{
      padding: 0;
      color: #1d11f3;
    }
    //.cabinet-form-block{}
    &-block {
      position: relative;
      margin-bottom: 40px;
      background: $w;
      border: 1px solid #f1f1f1;
      //.cabinet-form-block__top{}
      &__top {
        background-color: var(--gx-color-pink3);
        @include flex(lc);
        padding: 1px 20px;  
        @include mobile-lg {
          flex-wrap: wrap;
        }
      }
      //.cabinet-form-block__heading{}
      &__heading {
        @include h(3);
      }
      //.cabinet-form-block__content{}
      &__content {
        position: relative;
        padding: 20px 20px 30px;
        @include mobile-lgm {
          padding: 0;
        }
        small {
          color: $dg2;
        }
      }
    }
    //.cabinet-form__link-feedback{}
    &__link-feedback {
      color: $a2;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
    //.cabinet-form__group{}
    &__group{
      padding: 0;
    }
    //.cabinet-form__row{}
    &__row {
      // @include row(10);
      display: grid;
      grid-template-columns: 50% 50%;
      grid-column-gap: 10px;
      @include tablet-md {
        display: block;
      }
    }
    //.cabinet-form__col{}
    &__col {
      // @include col(6, 10);
      // @include mobile {
      //   @include col(12, 10);
      // }
      // width: 100%;
      margin: 10px;
    }
    //.cabinet-form__end{}
    &__end {
      @include flex(sc);

      // @include mobile-lgm {
      //   flex-direction: column;
      // }
      //.cabinet-form__end--right{}
      &--right {
        text-align: right;
        @include mobile {
          margin-top: 15px;
        }
      }
      //.cabinet-form__end--left{}
      &--left{
        padding: 0;
      }
    }
  }
  //.cabinet-addresses{}
  &-addresses {
    @include row(10);
    margin-top: 10px;
  }
  //.cabinet-address{}
  &-address {
    @include col(4, 10);
    @include flex(lt);
    border: 2px solid #f3eff3;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 18px 20px;
    transition: 0.2s;

    @include tablet-lg {
      @include col(6, 10);
    }

    @include mobile {
      @include col(12, 10);
    }

    &:hover {
      background: #fcfbfc;
    }
     //.cabinet-address__link{}
     &__link{
      padding: 0;
      color: $rd;
    }
    //.cabinet-address__link--blue{}
    &__link--blue{
      padding: 0;
      color: rgb(27, 34, 236);
    }
    //.cabinet-address__new{}
    &__new {
      @include col(4, 10);
      @include flex(cc);
      background: #f7f4fc;
      @extend %text-btn;
      text-align: center;
      color: $a2;
      margin-bottom: 20px;
      padding: 18px 20px;

      @include tablet-lg {
        @include col(6, 10);
      }

      @include mobile {
        @include col(12, 10);
      }

      &:hover {
        background: #fcfbfc;
        text-decoration: underline;
      }
      //.cabinet-address__new-label{}
      &__new-label{
        padding: 0;
      }
    }
    //.cabinet-address__icon{}
    &__icon {
      margin-right: 12px;
      flex-shrink: 0;
    }
    //.cabinet-address__desc{}
    &__desc {
      width: 100%;
      font-size: 14px;
      position: relative;
      height: 100%;
      padding-bottom: 54px;
    }
    //.cabinet-address__value{}
    &__value {
      font-weight: bold;
      margin-bottom: 12px;
    }
    //.cabinet-address__name{}
    &__name {
      margin-bottom: 10px;
    }
    //.cabinet-address__phone{}
    &__phone{
      padding: 0;
    }
    //.cabinet-address__actions{}
    &__actions {
      margin-top: 18px;
      @include flex(sc);
      // opacity: 0;
      transition: 0.2s;
      position: absolute;
      width: 100%;
      bottom: 0;
    }

    &:hover &__actions {
      // opacity: 1;
    }
  }

  &-heading {
    @include h(3);
    margin-bottom: 20px;
  }

  &-topfilter {
    margin-bottom: 20px;

    &__left {
      max-width: 280px;
    }
  }

  &-table {
    width: 100%;
    border-collapse: 0;
    border-spacing: 0;

    tr,
    td {
      &:hover {
        background-color: #f7f4fc;
      }
    }

    tr {
      border-bottom: 5px solid transparent;
    }

    th,
    td {
      padding: 11px 0 11px 20px;
    }

    th {
      background: $a2;
      color: $w;
      font-weight: bold;
      text-align: left;
      @extend %cab-h2;
      border-right: 2px solid $w;

      &:last-child {
        border-right: 0;
      }
    }

    &__tdfull {
      padding: 10px 0 !important;
    }

    &__th {
      position: relative;
    }

    &__thlink {
      opacity: 0.5;
      --icon-width: 16px;
      position: absolute;
      top: -8px;
      right: -20px;

      &:hover {
        opacity: 1;
      }
    }
  }
  //.cabinet-status{}
  &-status {
    font-weight: bold;
    color: $a;
    @include flex(lc);

    &__icon {
      flex-shrink: 0;
      @include flex(cc);
    }

    &__title {
      margin-left: 5px;
    }

    &--change,
    &--cancel {
      color: $rd;
    }

    &--send {
      color: $gr;
    }
    //.cabinet-status--graytext{}
    &--graytext{
      color: #555353;
    }
    //.cabinet-status--greentext{}
    &--greentext{
      color: #06b314;
    }
  }

  &-inforeview {
    background: $g;
    border-radius: 4px;
    padding: 30px;
    text-align: center;
    @include flex(cc);
    @include flexcol(n);

    &__heading {
      @extend %cab-h1;
      margin-bottom: 10px;
    }

    &__text {
      margin-bottom: 10px;
    }

    &__btns {
      @include flex(cc);
      flex-wrap: wrap;
    }

    &__btn {
      min-width: 210px;
      margin: 10px 10px 0;
    }
  }
  //.cabinet-text-small{}
  &-text-small {
    color: $dg2;
    margin-bottom: 20px;
    padding: 0 5px;
    @extend %text-small;
  }
  //.cabinet-balance{}
  &-balance {
    border: 2px solid $g;
    border-radius: 4px;
    padding: 20px;
    min-width: 216px;
    @include flex(lt);
    margin-right: 20px;
    margin-bottom: 30px;
    //.cabinet-balance__items{}
    &__items {
      @include flex(lt);
      flex-wrap: wrap;
      margin-top: 20px;
      @include tablet-md {
        justify-content: center;
      }
    }
    //.cabinet-balance__icon{}
    &__icon {
      flex-shrink: 0;
      margin-right: 15px;
      margin-top: 5px;
    }
    //.cabinet-balance__desc{}
    &__desc {
      width: 100%;
    }
    //.cabinet-balance__value{}
    &__value {
      @extend %cab-h1;
      color: $a;
      margin-bottom: 5px;
      //.cabinet-balance__value--greentext{}
      &--greentext {
        color: #06b314;
      }
    }
    //.cabinet-balance__text{}
    &__text {
      color: $dg2;
    }

  }
  //.cabinet-warning{}
  &-warning {
    @include flex(lt);
    margin-bottom: 30px;
    //.cabinet-warning__icon{}
    &__icon {
      flex-shrink: 0;
      opacity: 0.5;
      margin-right: 10px;
    }
    //.cabinet-warning__desc{}
    &__desc {
      width: 100%;
      a {
        @extend %text-small;
        color: $b;
      }
    }
    //.cabinet-warning__text{}
    &__text {
      margin-bottom: 10px;
    }
  }

  &__heading {
    @include h(3);
    margin-bottom: 20px;
  }
  &-modal{
    //.cabinet-modal__wrap
    &__wrap{
      padding: 0;
    }
    //.cabinet-modal__close-btn{}
    &__close-btn{
      padding: 0;
    }
    //.cabinet-modal__heading{}
    &__heading{
      padding: 0;
    }
    //.cabinet-modal__content{}
    &__content{
      padding: 0;
    }
  }
  //.cabinet-wrapper__change-phone{}
  &-wrapper__change-phone {
    &-code {
      padding: 0;
    }
  }

}


.tooltip {
    z-index: 99999;
    font-size: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.11);
    border: 2px solid var(--gx-color-gray);
    padding: 10px 7px;
    min-width: 160px;
    height: auto;
    position: relative;
    text-align: center;
    white-space: pre-line;
    overflow: hidden;
    
}

.newbottom {
  display: none;
}

.button {
  //.button--flex-start{}
  &--flex-start {
    display: flex;
    justify-content: flex-start;
    gx-button {
      margin-right: 10px;
    }
  }
}


