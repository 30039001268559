@import '../../styles/variables.scss';

//.layout__container{}
.layout{
    &__container{
        // max-width: $maxWidthContainer + px;
        display: flex;
        flex-direction: column;
        height: 100vh;
        margin: 0 auto;
        overflow-x: hidden;
        align-items: center;
        @include scrollbar;

        // overflow: auto; // не работает скрол
    }
    //.layout__header{}
    &__header{
        position: relative;
        flex: 0 0 120px;
        width: calc(100vw - 7px);
        max-width: 1440px;
        @include mobile{
            flex: 0 0 50px;
        }
    }
    // .layout__main{}
    &__main{
        position: relative;
        // padding: 0 15px;
        flex: 1 1 auto;
        max-width: 1440px;
        // margin: 0 auto;
        width: calc(100vw - 7px);
        // overflow-x: hidden;
        @include mobile-mlg{
            padding: 0;
            
        }
    }
    //.layout__footer{}
    &__footer{

        position: relative;
        flex: 0 0 150px;
        width: calc(100vw - 7px);
        // max-width: 1440px;
    }
}