@import '../../../styles/variables.scss';

.warning-block {

  &__wrapper {
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: rgba(252, 210, 215, 0.5);
    min-height: 48px;
    padding: 15px;
    //.warning-block__wrapper-icon{}
    &-icon {
      margin-right: 10px;
      height: 24px;
      min-width: 24px;
      position: absolute;
      left: 15px;
      top: 20px;
    }
//.warning-block__wrapper-text{}
    &-text {
      max-width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      padding: 0 30px;

      //.warning-block__wrapper-text-ellipsis{}
      &-ellipsis {
        height: 40px;
        overflow: hidden;
        display: inline-block;
      }

      //.warning-block__wrapper-text-btn{}
      &-btn {
        font-size: 14px;
        width: 100%;
        min-height: 30px;
        position: relative;
        z-index: 20;
        // top:-8px;
      }

      //.warning-block__wrapper-text-content{}
      &-content {
        width: 100%;
        font-size: 14px;
        position: relative;
        top: 0px;
        transition: all 2s ease;
        
        //.warning-block__wrapper-text-content--disable{}
        &--disable {
          font-size: 14px;
          position: absolute;
          z-index: 19;
          transition: all .5s ease-out;
          top: -100%;
          right: -100%;
          color: rgba(252, 210, 215, 0.5);
          opacity: 0;
        }
      }
    }
  }

  &__catalog {

    //.warning-block__catalog-wrapper{}
    &-wrapper,
    &-wrapper-mobile {
      transition: all 2s ease-out;
      // overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      justify-content: left;
      align-items: center;
      background-color: rgba(252, 210, 215, 0.5);
      border-radius: 2px;
      padding: 15px;
      position: relative;
      z-index: 20;
      max-height: 100%;
      margin-bottom: 25px;
      // left: -100%;
      // animation-name: info-show;
      // animation-duration: 5s;
      // animation-delay: 0s;
      cursor: pointer;
      //.warning-block__arrow-8{}
      .warning-block__arrow-8 {
        position: absolute;
        width: 15px;
        height: 15px;
        top: 0;
        right: 10px;

        &:before,
        &:after {
          content: '';
          position: absolute;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          border-left: 2px solid #c577bf;
          border-bottom: 2px solid #c577bf;
          animation: arrow-8 3s linear infinite;
        }

        &:after {
          animation: arrow-8 3s linear infinite -1.5s;
        }
      }

      //.warning-block__catalog-wrapper--active{}
      &--active {
        margin-bottom: 25px;
        transition: all 2s ease-out;
        font-size: 12px;
        justify-content: left;
        align-items: center;
        background-color: rgba(252, 210, 215, 0.5);
        border-radius: 2px;
        padding: 15px;
        position: relative;
        max-height: 100%;
        cursor: pointer;
        .warning-block__wrapper-text-ellipsis {
          display: none;
        }
        //.warning-block__arrow-8{}
        .warning-block__arrow-8 {
          position: absolute;
          width: 15px;
          height: 15px;
          // margin: 30px auto 80px auto;
          bottom: 10px;
          right: 10px;
          transform: rotate(180deg);
          transition: all .2 ease;

          &:before,
          &:after {
            content: '';
            position: absolute;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            border-left: 2px solid #c577bf;
            border-bottom: 2px solid #c577bf;
            animation: arrow-8 3s linear infinite;
          }

          &:after {
            animation: arrow-8 3s linear infinite -1.5s;
          }
        }
      }
    }

    &__icon {
      margin-right: 10px;
    }

    &__text {
      max-width: 100%;
    }
  }

}


@keyframes arrow-8 {
  0% {
    opacity: 0;
    transform: translate(0, -13px) rotate(-45deg);
  }

  10% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translate(0, 0) rotate(-45deg);
  }

  100% {
    opacity: 0;
    transform: translate(0, 13px) rotate(-45deg);
  }
}

@keyframes info-show{
  0% {
    left: -100%;
    opacity: 0;
    visibility: hidden;    
  }

  30% {
    opacity: 0.3;
    visibility: visible;
  }

  70% {
    opacity: 0.7;
    visibility: visible;
  }
 
  100% {
    left: 0;
    opacity: 1;
    visibility: visible;
  }
}