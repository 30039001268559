.offset {
    &--catalog {
        padding-top: 90px;
    }
    &--content{
        padding-top: 20px;
    }
    &--20{
        padding-top: 20px;
    }
    &--30{
        padding-top: 30px;
    }
    &--40{
        padding-top: 40px;
    }
    &--50{
        padding-top: 50px;
    }
}