@import '../../../../styles/variables.scss';
@import '../../../../styles/fonts/index.scss';

.productreviews {
    //.productreviews__tooltip-send{}
    &__tooltip-send{
        padding: 8px;
        left: 0 !important;
        bottom: 50px;
        min-height: 50px;
    }
    //.productreviews__form{}
    &__form {
        background: rgba(243, 239, 243, 0.3);
        padding: 10px 20px 20px;
        width: 100%;
        > div {
            > ul {
                bottom: 32px;
            }
        }
        //.productreviews__form--full{}
        &--full {
            background: var(--gx-color-white);
        }
    }

    //.productreviews__form-title{}
    &__form-title {
        @extend %font;
        font-size: 22px;
        line-height: 40px;
        border-bottom: 2px solid $g;
        margin-bottom: 10px;
        font-weight: 700;
    }

    //.productreviews__form-submit-wrappar{}
    &__form-submit-wrappar {
        @include flex('sc');
        margin-top: 25px;
        &>:first-child{
            align-self: flex-start;
            margin-top: 10px;
            font-size: 12px;
            &>label{
                font-size: 12px;
                margin-right: 25px;
                &>:first-child{
                    margin-left: 10px;
                }
                &>:last-child{
                    margin-left: 25px;
                    border: none;
                }
            }
        }

        @include tablet-mmd {
            flex-direction: column;
            & > div{
                margin-bottom: 15px;
            }
        }
    }

    //.productreviews__form-submit-btnwrap{}
    &__form-submit-btnwrap {
        width: min-content;
        
        @include tablet-mmd {
            margin: 0px;
            justify-content: space-evenly;
            display: flex;
            min-width: 270px;
            flex-direction: row;
            width: 100%;
        }
    }

    //.productreviews__form-submit-btndark{}
    &__form-submit-btndark {
        margin: 5px;
        width: 100%;
        overflow: hidden;
        text-transform: uppercase;
        color: var(--gx-color-white);
        font-weight: bold;
        font-size: 14px;
        background-color: var(--gx-color-primary);
        max-width: 417px;
        width: 142px;
        letter-spacing: 1.4px;
        min-height: 46px;
        max-height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 15px;
        // max-width: min-content; // ломаеться в 
    }

    //.productreviews__form-submit-btn{}
    &__form-submit-btn {
        margin: 5px;
        text-transform: uppercase;
        background-color: transparent;
        color: var(--gx-color-primary);
        border: 2px solid var(--gx-color-primary);
        font-weight: bold;
        font-size: 14px;
        max-width: 417px;
        width: 142px;
        letter-spacing: 1.4px;
        min-height: 46px;
        max-height: 46px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    //.productreviews__form-raiting{}
    &__form-raiting {
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        align-content: center;
        &>:first-child{
            margin-right: 5px;
            font-size: 15px;
            letter-spacing: 1.1px;
            line-height: 160%;
        }
        @include tablet-md {
            margin-bottom: 15px;
        }
    }

    //.productreviews__form-rating-indicato{}
    &__form-rating-indicato {
        padding: 0;
    }

    //.productreviews__form-upload{}
    &__form-upload {
        padding: 0;
        display: flex;
        @include tablet-md{
            margin-bottom: 15px;
        }
    }

    //.productreviews__form-upload-desc{}
    &__form-upload-desc {
        padding: 0;
        font-size: 14px;
        color: $dg2;
        align-self: center;
    }

    //.productreviews__form-upload-list{}
    &__form-upload-list {
        padding: 0;
        @include ul;
        @include row-flex;
        padding: 7px 7px 0;
    }

    //.productreviews__form-upload-item{}
    &__form-upload-item {
        padding: 0;
        margin-right: 5px;

        &:last-child {
            margin: 0;
        }
    }

    //.productreviews__form-upload-image{}
    &__form-upload-image {
        padding: 0;
        width: 20px;
        
    }

    //.productreviews__form-addprod-image{}
    &__form-addprod-image {
        padding: 0;
    }

    //.productreviews__form-addprod-image-wrap{}
    &__form-addprod-image-wrap {
        padding: 0;
    }

    //.productreviews__form-upload-button{}
    &__form-upload-button {
        color: var(--gx-color-primary);
        font-size: 15px;
        padding: 0px 5px;
        letter-spacing: 0.4px;
        border: none;
        position: relative;
        background: transparent;
        border-bottom: 1px dashed var(--gx-color-primary);
        margin-bottom: 10px;
        // height: 28px;
        max-width: 86px;
        transform: translate(0px, 6px);

        &input:hover {
            cursor: pointer;
            color: $a2;
        }

    }

    //.productreviews__form-textarea{}
    &__form-textarea {
        padding: 0;
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 121px;
        font-family: var(--gx-input-font-family);
        font-weight: var(--gx-input-font-weight);
        line-height: var(--gx-line-height-normal);
        letter-spacing: var(--gx-input-letter-spacing);
        background-color: var(--gx-input-background-color);
        vertical-align: middle;
        transition: var(--gx-transition-fast) color, var(--gx-transition-fast) border, var(--gx-transition-fast) box-shadow;
        cursor: text;
        flex: 1 1 auto;
        color: var(--gx-input-color);
        border-radius: var(--gx-input-border-radius-medium);
        font-size: var(--gx-input-font-size-medium);
        box-shadow: inset 1px 1px 2px 1px $dg, inset -1px -1px 2px 1px $dg;
        padding: 15px;

        &::placeholder {
            user-select: none;
            color: $dg2;
            font-size: 16px;
            letter-spacing: 1.1px;
            line-height: 140%;
        }
    }

    //.
}