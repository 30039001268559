@import '../../../styles/variables.scss';

.btn {
    @include flex('cc');
    padding: 30px 26px;
    outline: none;
    text-transform: uppercase;
    font-size: 14px;
    // font-weight: bold;
    color: var(--gx-color-primary);
    line-height: 140%;
    letter-spacing: .1em;
    border: 2px solid var(--gx-color-primary);
    background: linear-gradient(180deg, #FED6DB 0%, #EF9DA8 50%, #EF9DA8 100%);
    background-size: 1px 200%;
    box-shadow: 0px 18px 18px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    transition: background .3s ease-in;
    overflow: hidden;
    min-width: 245px;
    width: 245px;
    height: 88px;
    &:hover {
        background-position: 100%;
    }

    &:active {
        outline: none;
        box-shadow: 0px 26px 18px rgba(0, 0, 0, 0.2);
    }

}