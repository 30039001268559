@import '../../../styles/fonts/index.scss';

.personalInfo{
  //.personalInfo__wrapper{}
  &__wrapper {
    position: relative;
    padding: 0 15px;
    width: 100%;
    display: flex;
    justify-content: left;
    //.personalInfo__wrapper-ellipse{}
    &-ellipse {
      // font-family: Cormorant Garamond;
      @extend %font-title;
      height: 60px;
      width: 60px;
      font-size: 32px;
      border-radius: 60px;
      align-items: center;
      line-height: 170%;
      border: 2px solid var(--gx-color-lightmauve);
      box-sizing: border-box;
      text-align: center;
      text-transform: uppercase;
    }
    //.personalInfo__wrapper-name_and_role{}
    &-name_and_role {
      font-weight: bold;
      font-size: 16px;
      color: var(--gx-color-primary);
      letter-spacing: 0.02em;
      padding-left: 10px;
      max-width: calc(100% - 60px);
      word-wrap: break-word;
      p {
        margin: 0;
      }
      //.personalInfo__wrapper-name_and_role-name{}
      &-name {
      }
      //.personalInfo__wrapper-name_and_role-role{}
      &-role {
        position: relative;
        // top: -5px;
        font-weight: normal;
        font-size: 12px;
        opacity: 0.5;
      }
    }
  }
}