@import '../../../styles/variables.scss';
@import '../../../styles/fonts/index.scss';

.grid{
  //.grid__wrapper{}
  &__wrapper{
    width: 100%;
    height: 100%;
    padding: 5px;
  }
  //.grid__container{}
  &__container{
    display: grid;
    grid-auto-columns: auto;
    grid-auto-rows: auto;
  }
  //.grid__center{}
  &__center{
    margin: 0 auto;
    min-width: 270px;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  //.grid__coll{}
  &__coll{
    padding: 0;
    min-width: 270px;
  }
  //.grid__row{}
  &__row{
    padding: 15px 0 0;
  }
  //.grid__left-side{}
  &__left-side{
      padding: 0;
      min-width: 320px;
      margin-bottom: 10px;
  }
  //.grid__right-side{}
  &__right-side{    
      padding: 0 15px;
      min-width: 320px;
  }
  //.grid__body{}
  &__body{    
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0, 1fr);
    // width: 95vw;
    // height: 70vh;
    overflow: hidden;
    margin-top: 20px;
    @include scrollbar;
    @include tablet-mmd{
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        height: 100%;
        width: 100%;
        user-select: none;
    }
    //.preview-product-modal__body--modal{}
    &--modal{
        height: none;
        // overflow-y: scroll;
        .modal-provider__body--show{
            height: 100%;
        }
    }
  }

}

.block{
  
  //.block__container{}
  &__container {
    margin: 0 auto;
    width: 100%;
    max-width: $maxWidthContainer + px;
    padding: 0 10px;
    position: relative;
    
    @include mobile {
      padding: 0;
    }
  }
  
  //.block__feedback-content{}
  &__feedback-content{
    padding: 0; 
  }
  //.block__payment-content{}
  &__payment-content{
    padding: 5px;
  }
  //.block__message-content{}
  &__message-content{
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
  }
  &-add-address {
    //.block-add-address__label{}
    &__label{
      font-size: calc($fontSize - 3px);
      color: $dg2;
      @extend %font;
      margin: 0 0 3px 10px;
    }
    //.block-add-address__conteiner{}
    &__conteiner {
      padding: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      margin-bottom: 15px;
      @include mobile {
        grid-template-columns: 1fr;
      }
      //.block-add-address__conteiner-phone{}
      &-phone{
        border: 1px solid $dg2;
        border-radius: 5px;
        padding: 0 10px;
        &::placeholder{
          font-size: 12px;
        }
      }
    }
    //.block-add-address__left-side{}
    &__left-side{
      padding: 0;
    }
    //.block-add-address__cell{}
    &__cell{
      padding: 0;
      margin-bottom: 15px;
      //.block-add-address__cell--container{}
      &--container{
        padding: 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &>div{
          flex: 0 1 45%;
          &>div>input::placeholder{
            font-size: 14px;
          }
        }
        
      }
    }
    //.block-add-address__container-button{}
    &__container-button{
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      min-height: 58px;
      @include mobile {
        flex-direction: column;
        padding: 10px;
        &>button{
          margin: 0;
          min-width: 270px;
          max-width: 280px;
          &:not(:last-child){
            margin-bottom: 10px;
          }
        }
      }
      &>button{
        margin: 0;
        max-width: 300px;        
      }


    }
    //.block-add-address__additional-info{}
    &__additional-info{
      padding: 0;
      &>span{
        margin: 0 0 0 5px;
        color: $a2;
        &:hover{
          color: $a;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

}

.block-page {
  //.block-page__grid-container-lk{}
  &__grid-container-lk{
    display: grid;
    grid-template-columns: 2.5fr 9.5fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    @media (max-width: 1080px) {
        grid-template-columns: 1fr;
    }
  }
  //.block-page__container{
  &__container {
      display: grid;
      grid-template-columns: 8fr 4fr;
      grid-template-rows: auto;
      @media (max-width: 1080px) {
          grid-template-columns: 1fr;
      }
  }

  //.block-page__left-side{}
  &__left-side {
      padding: 0;
  }
  //.block-page__left-side-lk{}
  &__left-side-lk{
    padding: 0;
    min-width: 270px;
  }

  //.block-page__right-side{}
  &__right-side {
      padding: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      margin-bottom: 30px;
      width: calc(100% - 30px);
  }
  //.block-page__right-side-lk{}
  &__right-side-lk{
    min-width: 270px;
    padding: 0;
    @include mobile{
      padding: 0 5px;
    }
  }
}

.preview-product-modal__row{
  display: flex;
}
